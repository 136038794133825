import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { applyDepositCertificate, getCertificate, getProducts, RequestCertificateProps, requestDepositCertificate } from '@/services/products'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import RequestAccount from '../../account/request/RequestAccount'
import { LoadingRequest } from '../../request/LoadingRequest'
import { CreatedCertificate } from './CreatedCertificate'
import { DepositCertificateContract } from './DepositCertificateContract'
import { DepositCertificateExpiresForm } from './DepositCertificateExpiresForm'
import { DepositCertificateForm } from './DepositCertificateForm'

type Props = {
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

enum Pages {
  FORM_DATA,
  EXPIRES,
  CONTRACT,
  SUCCESS,
  ERROR,
}

export const RequestDepositCertificate = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState<Pages>(Pages.FORM_DATA)
  const [params, setParams] = useState<RequestCertificateProps>({
    amount: 0,
    duration: 12,
    accountToDebitUUID: '',
    paymemtMethod: 'capitalize',
    autoRenewDuration: 12
  })
  const queryClient = useQueryClient()

  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: ['products'],
    queryFn: getProducts,
    enabled: open,
    staleTime: 1000 * 60 * 5
  })
  const { accounts = [] } = products ?? {}

  const aplicationMutation = useMutation({
    mutationFn: () => applyDepositCertificate(params),
    onSuccess: () => setPage(Pages.CONTRACT),
    onError: (error) => {
      toast.error(error.message || 'Error: Could not apply for the certificate. Please try later.')
      setPage(Pages.ERROR)
    }
  })

  const requestMutation = useMutation({
    mutationFn: () => {
      if (!aplicationMutation.data?.id) {
        throw new Error('Application ID is missing.')
      }
      return requestDepositCertificate({ applyId: aplicationMutation.data.id })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['products'] })
      setPage(Pages.SUCCESS)
    },
    onError: (error) => {
      toast.error(error.message || 'Error: Could not request the certificate. Please try later.')
      setPage(Pages.ERROR)
    }
  })

  const { data: certificate, isLoading: isLoadingCertificate, isError: isCertificateError, error: certificateError } = useQuery({
    queryKey: ['certificate', requestMutation.data],
    queryFn: () => getCertificate(requestMutation.data),
    enabled: !!requestMutation.data
  })

  useEffect(() => {
    if (isCertificateError) {
      toast.error(certificateError?.message || 'Error: Could not retrieve the certificate. Please try later.')
    }
  }, [isCertificateError])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className='sm:max-w-[850px] bg-[#F7F7FF]'>
        {isLoadingProducts || isLoadingCertificate || aplicationMutation.isPending || requestMutation.isPending ? (
          <LoadingRequest productName='deposit certificate' />
        ) : (
          <>
            {accounts.length === 0 && (
              <section>
                <h2 className='font-semibold'>No accounts</h2>
                <p className='text-slate-600 text-sm'>
                  {'You need an account to request a deposit certificate. You don\'t have any, do you want to add one?'}
                </p>
                <RequestAccount>
                  <Button onClick={() => { }}>Add Account</Button>
                </RequestAccount>
              </section>
            )}
            {accounts.length > 0 && page === Pages.FORM_DATA && (
              <DepositCertificateForm params={params} setParams={setParams} onContinue={() => setPage(Pages.EXPIRES)} />
            )}
            {page === Pages.EXPIRES && (
              <DepositCertificateExpiresForm
                params={params}
                setParams={setParams}
                onPrevious={() => setPage(Pages.FORM_DATA)}
                onContinue={() => aplicationMutation.mutate()}
              />
            )}
            {page === Pages.CONTRACT && (
              <DepositCertificateContract
                aplicationCeretificate={aplicationMutation.data!}
                loading={aplicationMutation.isPending || requestMutation.isPending}
                onAccept={() => requestMutation.mutate()}
              />
            )}
            {page === Pages.SUCCESS && certificate && <CreatedCertificate certificate={certificate} />}
            {page === Pages.ERROR && <p className='text-red-500'>An error occurred. Please try again later.</p>}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}


// API response structure is not as expected: [
//   {
//     "code": "invalid_type",
//     "expected": "string",
//     "received": "undefined",
//     "path": [
//       "number"
//     ],
//     "message": "Required"
//   },
//   {
//     "code": "invalid_type",
//     "expected": "number",
//     "received": "undefined",
//     "path": [
//       "balanceAvailable"
//     ],
//     "message": "Required"
//   },
//   {
//     "code": "invalid_type",
//     "expected": "object",
//     "received": "null",
//     "path": [
//       "accountToCredit"
//     ],
//     "message": "Expected object, received null"
//   },
//   {
//     "code": "invalid_type",
//     "expected": "string",
//     "received": "undefined",
//     "path": [
//       "nextPaymentDate"
//     ],
//     "message": "Required"
//   },
//   {
//     "received": "Active",
//     "code": "invalid_enum_value",
//     "options": [
//       "active",
//       "block",
//       "cancelled"
//     ],
//     "path": [
//       "status"
//     ],
//     "message": "Invalid enum value. Expected 'active' | 'block' | 'cancelled', received 'Active'"
//   }
// ]