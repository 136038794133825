import { Button, buttonVariants } from '@/components/ui/button'
import { useSidebar } from '@/hooks/useSidebar'
import { cn } from '@/lib/utils'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { useSession } from '@/hooks/useSession'
import { AUTH_ROUTES } from '@/lib/constants'
import { auth } from '@/services/firebaseConfig'
import { NavItem } from '@/types'
import { LogOut, LucideIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'wouter'
import { SidebarItemOptions } from '../common/SidebarItemOptions'

interface SideNavProps {
  setOpen?: (open: boolean) => void;
  className?: string;
}
const openSidebarClassName = 'text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100'
const SideItem = ({ item, setOpen, isOpen }: { item: NavItem, setOpen?: (open: boolean) => void, isOpen?: boolean }) => {
  const { title, href, icon, color } = item
  const Icon: LucideIcon = icon!
  const [location] = useLocation()
  return (
    <Link
      key={title}
      href={href}
      onClick={() => { if (setOpen) setOpen(false) }}
      className={cn(
        buttonVariants({ variant: 'ghost' }),
        'group relative flex h-10 justify-start',
        location === href && 'bg-csecondary/10 hover:bg-muted rounded-lg',
        !isOpen && 'justify-center'
      )}
    >
      {<Icon className={cn('h-5 w-5 text-csecondary', color)} />}
      <span className={cn('absolute left-12 text-base duration-200', !isOpen && openSidebarClassName)}>
        {title}
      </span>
    </Link>
  )
}

export function SideNav({ setOpen, className }: SideNavProps) {
  const { isOpen } = useSidebar()
  const [openItem, setOpenItem] = useState('')
  const [lastOpenItem, setLastOpenItem] = useState('')
  const [, setLocation] = useLocation()
  const { reset } = useSession()
  const logout = async () => {
    localStorage.removeItem('token')
    await auth.signOut()
    reset()
    setLocation('/login')
  }

  useEffect(() => {
    if (isOpen) {
      setOpenItem(lastOpenItem)
    } else {
      setLastOpenItem(openItem)
      setOpenItem('')
    }
  }, [isOpen])


  return (
    <nav className={cn('space-y-2 flex flex-col h-full px-3 pb-6 mt-8 overflow-y-auto overflow-x-hidden', className)}>
      {AUTH_ROUTES.filter((item) => item.title !== undefined && item.icon !== undefined).map((item) => {
        const { title, children, icon, color } = item
        const Icon: LucideIcon | undefined = icon 
        return children ? (
          <SidebarItemOptions key={title} item={item}>
            <div
              className={cn(
                buttonVariants({ variant: 'ghost' }),
                'group relative flex h-10 justify-start cursor-pointer'
              )}
            >
              {Icon && <Icon className={cn('h-5 w-5 text-csecondary', color)} />} 
              <span className={cn('absolute left-12 text-base duration-200')}>
                {title}
              </span>
            </div>
          </SidebarItemOptions>
        ) : (
          <SideItem key={title} item={item} setOpen={setOpen} isOpen={isOpen} />
        )
      })}
      <AlertDialog>
        <AlertDialogTrigger className='!mt-auto '>
          <Button
            variant='ghost'
            className={cn('group relative flex h-10 justify-start text-red-600/90 dark:text-red-400/70 cursor-pointer gap-2 w-full text-base', !isOpen && 'justify-center')

            }>
            <LogOut className='size-5' />
            <span className={cn('absolute left-12 text-base duration-200', !isOpen && openSidebarClassName)}>
              Cerrar Sesión
            </span>
          </Button>

        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Log Out</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to log out?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className='w-24'>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={logout} className={cn('w-24', buttonVariants({ variant: 'destructive' }))}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <section className='text-slate-400 text-xs text-center mb-4 !mt-auto pt-4'>
        Version 1.0.0
      </section>
    </nav >
  )
}
