import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

type Element = {
  label: string,
  value: string
}

type Props = {
  value: string;
  onChange: (e: string) => void;
  elements: Element[];
}
export function CustomRadioGroup({ onChange, value, elements }: Props) {
  return (
    <RadioGroup className='flex gap-4' onValueChange={onChange} value={value} >
      {elements.map((e) => {
        return (
          <div key={e.value} className='flex items-center space-x-2'>
            <RadioGroupItem value={e.value} id={e.value} />
            <Label htmlFor={e.value}>{e.label}</Label>
          </div>)
      })}
    </RadioGroup>
  )
}
