import {
  Select,
  SelectContent,
  SelectTrigger
} from '@/components/ui/select'
import { fmCurrency } from '@/lib/constants'
import { cn } from '@/lib/utils'
import { getProducts } from '@/services/products'
import { Account } from '@/types/product'
import { useQuery } from '@tanstack/react-query'
import { Check } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Isotipo } from '../icons/Isotipo'

type OptionProps = {
  className?: string;
  account: Account;
  showIcon?: boolean;
  classNameAmount?: string;
};

const ItemOption = ({ account, className = '', classNameAmount = '', showIcon = true }: OptionProps) => {
  return (
    <div className={cn('flex w-full items-center', className)}>
      {showIcon && (
        <div className='size-10 border border-slate-100 dark:border-dark-border rounded-full flex items-center justify-center'>
          <Isotipo size={25} />
        </div>
      )}
      <div className='text-start ms-2'>
        <h1 className='text-xs'>Saving Account</h1>
        <span className='text-slate-400 text-xs'>{account.number}</span>
      </div>
      <span className={`ms-auto me-4 text-lg ${classNameAmount}`}>
        {fmCurrency(account.amount)}
      </span>
    </div>
  )
}

type Props = {
  onValueChange?: (value: string) => void;
  selected?: Account | null;
  setSelected: (value: Account) => void;
  disabled?: boolean;
};

export function SelectAccountFromList({
  selected = null,
  setSelected,
  disabled,
  onValueChange
}: Props) {
  const [open, setOpen] = useState(false)
  const { isLoading, isError, data: products } = useQuery({
    queryKey: ['products'],
    queryFn: getProducts,
    staleTime: 1000 * 60 * 5
  })

  const accounts = products?.accounts ?? []

  const handleChange = (account: Account) => {
    onValueChange?.(account.id)
    setSelected(account)
    setOpen(false)
  }

  useEffect(() => {
    if (accounts.length > 0) handleChange(accounts[0])
  }, [accounts])

  if (isLoading) {
    return <div className='w-full h-14 bg-slate-200 dark:bg-card animate-pulse rounded-xl' />
  }

  if (isError) {
    return <div className='w-full h-14 bg-red-200 dark:bg-card rounded-xl flex items-center justify-center text-red-600'>Failed to load accounts</div>
  }

  return (
    <Select
      open={open}
      disabled={disabled}
      onOpenChange={setOpen}
      onValueChange={(value) => {
        const selectedAccount = accounts.find((account) => account.id === value)
        if (selectedAccount) handleChange(selectedAccount)
      }}
    >
      <SelectTrigger
        className='h-14 rounded-xl border-slate-100 dark:bg-card dark:border-dark-border'
        aria-label='Select account'
      >
        {selected && <ItemOption account={selected} />}
      </SelectTrigger>
      <SelectContent>
        {accounts.map((account) => (
          <div
            key={account.id}
            className='flex items-center ps-2 cursor-pointer'
            onClick={() => handleChange(account)}
          >
            <Check
              size={18}
              className={`${account.id !== selected?.id ? 'opacity-0' : ''}`}
            />
            <ItemOption account={account} showIcon={false} classNameAmount='text-sm ' />
          </div>
        ))}
      </SelectContent>
    </Select>
  )
}
