import { CustomRadioGroup } from '@/components/common/CustomRadioGroup'
import { InputMoney } from '@/components/transfers/InputMoney'
import { SelectAccountFromList } from '@/components/transfers/SelectAccountFromList'
import { Alert, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { RequestCertificateProps } from '@/services/products'
import { Account } from '@/types/product'
import { RocketIcon } from 'lucide-react'
import { useState } from 'react'

type Props = {
  onContinue: () => void
  params: RequestCertificateProps
  setParams: React.Dispatch<React.SetStateAction<RequestCertificateProps>>
}

export const interestPaymentMethods = [
  {
    label: 'Capitalizable',
    value: 'capitalize',
    message: 'Your interest will be capitalized in this certificate'
  },
  {
    label: 'Credit on account',
    value: 'credit',
    message: 'Your interest will be credited in this account'
  }
]
export const DepositCertificateForm = ({ params, setParams, onContinue }: Props) => {
  // const { accounts } = useProducts()
  const [accountToDebit, setAccountToDebit] = useState<Account>()
  const [accountToCredit, setAccountToCredit] = useState<Account>()
  const handlerAccountToDebit = (account: Account) => {
    setAccountToDebit(account)
    setParams({ ...params, accountToDebitUUID: account.id })
  }
  const handlerAccountToCredit = (account: Account) => {
    setAccountToCredit(account)
    setParams({ ...params, accountToCreditUUID: account.id })
  }
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle className='text-slate-800 dark:text-slate-200'>
          Request your <span className='text-primary'>Certificate</span>
        </DialogTitle>
      </DialogHeader>
      <form onSubmit={onContinue}>
        <div className='grid grid-cols-2 gap-5 py-4'>
          <div>
            <InputMoney value={params.amount} onChange={(e) => setParams({ ...params, amount: e })} className='dark:border-dark-border dark:bg-card' />
          </div>
          <div>
            <div className='grid gap-2'>
              <Label className='font-semibold'>Interest payments</Label>
              <CustomRadioGroup
                onChange={(value) => setParams(prev => ({ ...prev, paymemtMethod: value }))}
                value={params.paymemtMethod}
                elements={interestPaymentMethods.map((e) => ({ label: e.label, value: e.value }))} />
            </div>
            <Alert className='mt-2'>
              <RocketIcon className='h-4 w-4' />
              <AlertTitle className='text-xs mb-0'>{interestPaymentMethods.find((e) => e.value === params.paymemtMethod)?.message}</AlertTitle>
            </Alert>
          </div>
          <div className='grid gap-1'>
            <SelectAccountFromList selected={accountToDebit} setSelected={handlerAccountToDebit} />
          </div>
          {params.paymemtMethod === 'capitalize' && <div />}
          {params.paymemtMethod === 'credit' && <div className='grid gap-1'>
            <SelectAccountFromList selected={accountToCredit} setSelected={handlerAccountToCredit} />
          </div>}
          <div className='grid gap-2'>
            <Label className='font-semibold'>Term <span className='ml-1 bg-purple-100 text-purple-800 px-2 py-[1px] rounded '>{params.duration} months</span></Label>
            <div className='flex gap-3 items-center'>
              <span className='text-sm text-slate-900 dark:text-slate-500'>1</span>
              <Slider
                className='text-primary'
                defaultValue={[params.duration]}
                max={36}
                step={1}
                min={1}
                onValueChange={(e) => setParams(prev => ({ ...prev, duration: e[0] }))}
              />
              <span className='text-sm text-slate-900 dark:text-slate-500'>36</span>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button disabled={params.amount <= 0 || (accountToDebit?.balanceAvailable??0) < params.amount}>Continue</Button>
        </DialogFooter>
      </form>
    </>
  )
}

// {
//   "message": "Certificate created successfully",
//   "data": {
//       "id": 64,
//       "financial_product_id": {
//           "id": 153,
//           "uuid": "df2a6dfc-050f-4f45-9086-b1ce4ba46524",
//           "created_at": "2024-07-09T10:08:04.802439",
//           "updated_at": "2024-07-09T10:08:04.802460",
//           "user_id": 7,
//           "financial_product_type_id": 5,
//           "financial_product_request_id": 254
//       },
//       "interest_payment_method_id": {
//           "id": 1,
//           "created_at": "2019-01-01T00:00:00",
//           "updated_at": "2019-01-01T00:00:00",
//           "description": "Credito a cuenta",
//           "details": "1-Credito a cuenta = El interes generado sera depositado a tu cuenta lira cada mes."
//       },
//       "created_at": "2024-07-09T10:08:04.825108",
//       "updated_at": "2024-07-09T10:08:04.825129",
//       "description": "Certificate",
//       "apr": 6,
//       "original_amount": "500.0000",
//       "interest_gained_not_paid": "0.0000",
//       "capitalized_amount": "0.0000",
//       "current_amount": "500.0000",
//       "date_of_disbursement": "2024-07-09",
//       "total_interest_paid": "0.0000",
//       "interest_payment_frequency": "Monthly",
//       "date_of_next_interest_payment": 1,
//       "renewal_date": "2025-01-09",
//       "design": {},
//       "settings": {
//           "auto_renew_duration": 12,
//           "cancellation_penalty": {
//               "applies": true,
//               "percentage": 5
//           }
//       },
//       "short_number": "20240709483582",
//       "user_id": 7,
//       "financial_certificate_type_id": 1,
//       "financial_certificate_status_id": 1,
//       "debit_to_financial_product_id": 6,
//       "credit_to_financial_product_id": 114
//   },
//   "status": "success"
// }