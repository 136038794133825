import { auth } from '@/services/firebaseConfig'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type User = {
  id: string
  name: string
  lastName: string
  email: string
}

type SessionStore = {
  token?: string | null
  idToken?: string | null
  refreshToken?: string | null
  user?: User | null
  setToken: (token: string) => void
  setToken2: (idToken: string, refreshToken: string) => void
  setUser: (user: User) => void
  reset: () => void
  checkSession: () => boolean
}

const DEFAULT_STATE = {
  token: null,
  user: null
}

// PUEDE SER REEMPLAZADO ELIMINANDO LA STORE TODO:REMPLAZAR
export const useSession = create<SessionStore>()(persist(
  (set) => ({
    setToken: (token: string) => set({ token }),
    setToken2: (idToken: string, refreshToken: string) => set({ idToken, refreshToken }),
    setUser: (user: User) => set({ user }),
    reset: () => set({ ...DEFAULT_STATE }),
    signOut: async () => {
      await auth.signOut()
      set({ ...DEFAULT_STATE })
    },
    checkSession: () => {
      try {
        // Obtener la sesión desde localStorage
        const sessionData = localStorage.getItem('session')
        if (!sessionData) return false

        const { state = {} } = JSON.parse(sessionData)
        const token = state.token

        if (!token) return false

        // Decodifica el token JWT para leer el payload
        const payload = JSON.parse(atob(token.split('.')[1]))
        const currentTime = Date.now() / 1000 // Tiempo actual en segundos

        // Comprueba si el token ha vencido
        return payload.exp > currentTime

      } catch (error) {
        return false
      }
    }
  }),
  { name: 'session' }
))

