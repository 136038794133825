import { Logo } from '@/components/icons/Logo'
import { Button } from '@/components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { Card } from '@/types/product'
import { CreditCardDetails } from '../CreditCardDetails'
import { useLocation } from 'wouter'

type Props = {
  card: Card | null
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

export const CreatedCreditCard = ({ card }: Props) => {
  const [, setLocation] = useLocation()
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle>Credit Card</DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-3 py-4 justify-center'>
        <div>
          <h2 className='font-semibold text-xl text-primary text-center'>Congratulations, your credit card have been created :)</h2>
          <div className='flex justify-center mt-2'><Logo size={80} /></div>
        </div>
        <div className='text-center'>
          <h2 className='font-semibold text-xl text-primary'>{card?.number}</h2>
          <span className='text-sm'>Credit card number</span>
        </div>
        <p className='text-sm text-center'>Boom! Your credit card and its ready and rolling. Congratulations!, Now you are a step closer to conqueer your finantial goals. Let{'\''}s save!</p>
      </div>
      <DialogFooter>
        <div className='flex flex-col justify-end gap-4'>
          <CreditCardDetails card={card ?? undefined}>
            <Button onClick={() => setLocation(`/products/cards/${card?.id}`)} className='w-24 h-10 rounded-xl text-md dark:bg-primary dark:text-white dark:hover:bg-primary/90'>Details</Button>
          </CreditCardDetails>
        </div>
      </DialogFooter>
    </>
  )
}