import { SideNav } from '@/components/layout/Navbar'
import { useState } from 'react'

import { useSidebar } from '@/hooks/useSidebar'
import { cn } from '@/lib/utils'
import { ArrowLeft } from 'lucide-react'

// TODO: fixed bug with sidebar | rebuild sidebar

interface SidebarProps {
  className?: string;
}

export default function Sidebar({ className }: SidebarProps) {
  const { isOpen, toggle } = useSidebar()
  const [status, setStatus] = useState(false)

  const handleToggle = () => {
    setStatus(true)
    toggle()
    setTimeout(() => setStatus(false), 500)
  }
  return (
    <nav
      className={cn(
        'relative hidden h-screen dark:border-slate-800 pt-20 dark:bg-card border-r border-slate-100 md:flex flex-col justify-between',
        status && 'duration-500', isOpen ? 'w-64' : 'w-[78px]', className
      )}
    >
      <div className='absolute -right-3 top-20 cursor-pointer rounded-full border border-slate-200 dark:border-dark-border bg-white dark:bg-dark-border text-3xl text-foreground p-1' onClick={handleToggle}>
        <ArrowLeft className={cn('size-5 duration-75 transition-transform text-primary', isOpen && 'rotate-180')} />
      </div>
      <SideNav />
    </nav>
  )
}