// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAfrFHPhr-q8vC8G10Qm160AVtQ_NpfZEw',
  authDomain: 'flutter-lira-dev.firebaseapp.com',
  databaseURL: 'https://flutter-lira-dev-default-rtdb.firebaseio.com',
  projectId: 'flutter-lira-dev',
  storageBucket: 'flutter-lira-dev.appspot.com',
  messagingSenderId: '891304955151',
  appId: '1:891304955151:web:15bf00a3673d3ee482427a',
  measurementId: 'G-XHS38QGYMQ'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)

export { analytics, app, db }
