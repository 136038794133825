import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { ReactNode } from 'react'
import { InfoIcon } from '../icons/Info'

type ConfirmationDialogProps = {
  children: ReactNode;
};

export function WorkingDialog({ children }: ConfirmationDialogProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader className='flex flex-col items-center py-2'>
          <div className='p-4 mb-2 bg-red-100 fill-red-800 w-min rounded-full'>
            <InfoIcon size={20} />
          </div>
          <AlertDialogTitle>Feature Not Available</AlertDialogTitle>
          <AlertDialogDescription className='text-center'>
            <div className='text-sm text-slate-500 dark:text-slate-400'>
              This feature is still in progress and is not yet available. Please check back later.
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className='rounded-xl w-32'>
            OK
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}