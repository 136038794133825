import { Isotipo } from '@/components/icons/Isotipo'
import { GlobalSearch } from '@/components/layout/GlobalSearch'
import { MobileSidebar } from '@/components/layout/MobileSidebar'
import { ThemeToggle } from '@/components/layout/ThemeToggle'
import { UserNav } from '@/components/layout/UserNav'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator
} from '@/components/ui/breadcrumb'
import { AUTH_ROUTES } from '@/lib/constants'
import { cn } from '@/lib/utils'
import { Home } from 'lucide-react'
import { Link, useLocation } from 'wouter'
import { buttonVariants } from '../ui/button'
import { Notification } from './Notification'
export default function Header() {
  const [location] = useLocation()
  const paths = location.split('/')?.filter((path) => path !== '')
  return (
    <nav className='fixed left-0 right-0 top-0 z-20 border-b border-slate-100 dark:border-slate-800 dark:bg-card bg-white flex h-16 items-center justify-between px-4'>
      <section className='flex items-center'>
        <Link href='/' className='items-center gap-3 ms-2 md:me-24 hidden md:flex'>
          <div className='border rounded-full p-2 dark:border-dark-border'>
            <Isotipo size={30} />
          </div>
          <span className='self-center text-xl title font-semibold sm:text-4xl whitespace-nowrap text-primary dark:text-slate-100'>
            Lira
          </span>
        </Link>
        {
          paths.length > 0 &&
          <Breadcrumb className='hidden sm:flex'>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link href='/' className={cn('capitalize gap-2', buttonVariants({ variant: 'link' }))}>
                  <Home className='size-4 -mt-0.5' />Home
                </Link>
              </BreadcrumbItem>
              {
                paths.map((path, index) => {
                  const Icon = AUTH_ROUTES.find((item) => item.href === `/${path}`)?.icon
                  return (
                    <div key={`breadcrumb-${index}`} className='flex items-center'>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <Link href={`/${path}`}
                          className={cn('capitalize px-2.5 gap-2 flex items-center', index != paths.length - 1 ? buttonVariants({ variant: 'link' }) : 'pointer-events-none select-none')}
                        >
                          {Icon && <Icon className='size-4' />}{path}
                        </Link>
                      </BreadcrumbItem>
                    </div>
                  )
                })
              }
            </BreadcrumbList>
          </Breadcrumb>
        }
      </section>
      <MobileSidebar />
      <div className='flex items-center gap-3'>
        <GlobalSearch />
        <span className='text-gray-400 text-xs'>{(new Date()).toDateString()}</span>
        <Notification />
        <ThemeToggle />
        <UserNav />
      </div>
    </nav>
  )
}