import { Toaster } from '@/components/ui/sonner'
import { AUTH_ROUTES, UNAUTH_ROUTES } from '@/lib/constants'
import { ThemeProvider } from 'next-themes'
import { Fragment, Suspense, lazy, useEffect } from 'react'
import { Route, Switch, useLocation } from 'wouter'
import { Layout } from './components/layout/Layout'
import { useSession } from './hooks/useSession'

const Login = lazy(() => import('./pages/Login').then(m => ({ default: m.Login })))

function App() {
  const [, setLocation] = useLocation()
  const { checkSession } = useSession()
  useEffect(() => {
    if (!checkSession()) {
      // setLocation('/login')
    }
  }, [checkSession, setLocation])

  if (!checkSession()) return (
    <Suspense>
      <Login />
      <Toaster />
    </Suspense>
  )

  return (
    <ThemeProvider attribute='class' defaultTheme='system' enableSystem>
      <Switch>
        {AUTH_ROUTES.map(({ component: Component, href, children }) => (
          <Fragment key={href}>
            <Route key={href} path={href}>
              <Layout>
                <Suspense><Component /></Suspense>
              </Layout>
            </Route>
            {children && children.map(({ href: cHref, component: CComponent }) =>
              <Route key={cHref} path={cHref} >
                <Layout>
                  <Suspense><CComponent /></Suspense>
                </Layout>
              </Route>
            )}
          </Fragment>
        ))}
        {
          UNAUTH_ROUTES.map(({ href, component: Component }) =>
            <Route key={href} path={href}>
              <Suspense fallback={<div>hola</div>}><Component /></Suspense>
            </Route>
          )
        }
      </Switch>
      <Toaster />
    </ThemeProvider>
  )
}

export default App
