type Props = {
  className?: string
  style?: React.CSSProperties
  size: number
}
const Logo = (props: Props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    viewBox='0 0 270.61 178.22'
    width={props.size}
    height={props.size}
    {...props}
  >
    <path
      fill='#443DC4'
      d='M20.16 98.49c-.02 5.43 4.36 9.85 9.79 9.87H54c5.5.06 9.94 4.5 10 10v.16a9.203 9.203 0 0 1-3 7 9.659 9.659 0 0 1-7.08 3H30a28.96 28.96 0 0 1-21.2-8.81A28.903 28.903 0 0 1 0 98.49V10a9.655 9.655 0 0 1 3-7.12A9.684 9.684 0 0 1 10 0h.24a9.684 9.684 0 0 1 7 2.88 9.659 9.659 0 0 1 3 7.08l-.08 88.53zM164.25 33.73a9.659 9.659 0 0 1 7.08 3 9.763 9.763 0 0 1 2.88 7.07v.17c.05 2.63-.99 5.17-2.88 7a9.714 9.714 0 0 1-7.08 3h-4.69c-4.3-.08-8.44 1.61-11.44 4.69-3.01 3.01-4.7 7.09-4.69 11.34v28.4a9.203 9.203 0 0 1-3 7 9.558 9.558 0 0 1-7 3h-.25a9.558 9.558 0 0 1-7-3 9.203 9.203 0 0 1-3-7V70a35.79 35.79 0 0 1 10.61-25.67 35.01 35.01 0 0 1 25.67-10.62l4.79.02zM256.7 47.64a45.63 45.63 0 0 1 13.91 33.49v37.43a9.203 9.203 0 0 1-3 7 9.558 9.558 0 0 1-7 3h-.24a9.558 9.558 0 0 1-7-3 9.203 9.203 0 0 1-3-7c-6.25 6.67-15.32 10-27.23 10a45.63 45.63 0 0 1-33.49-13.91 45.615 45.615 0 0 1-13.9-33.48 45.637 45.637 0 0 1 13.9-33.49c18.41-18.5 48.33-18.58 66.84-.16l.16.16.05-.04zm-52.82 14.23c-5.15 5.07-8 12.03-7.88 19.26-.13 14.91 11.86 27.1 26.77 27.23h.47c14.97.07 27.16-12.02 27.23-26.99v-.24c-.03-15.07-12.27-27.27-27.34-27.24-7.21.01-14.12 2.88-19.23 7.98h-.02z'
    />
    <path
      fill='#8F9FEA'
      d='M86.19 23c-3.09.05-6.05-1.19-8.19-3.42-2.2-2.13-3.42-5.08-3.37-8.14-.04-3.04 1.18-5.96 3.37-8.07 4.45-4.48 11.69-4.5 16.16-.05l.05.05c2.25 2.08 3.5 5.01 3.45 8.07.06 3.08-1.19 6.04-3.45 8.14-2.09 2.2-4.99 3.43-8.02 3.42zM223.22 92.6c-6.34.19-11.63-4.8-11.81-11.14-.19-6.34 4.8-11.63 11.14-11.81.23-.01.45-.01.68 0 3.03-.04 5.95 1.17 8.06 3.35 4.48 4.29 4.63 11.39.35 15.86-.11.12-.23.23-.35.35-2.11 2.2-5.03 3.42-8.07 3.39z'
    />
    <path
      fill='#443DC4'
      d='M86.31 33.73a9.168 9.168 0 0 1 7 3 9.636 9.636 0 0 1 3 7.07v54.6c-.06 5.5-4.5 9.94-10 10h-.17a9.675 9.675 0 0 1-7.07-3 9.203 9.203 0 0 1-3-7V43.77c.06-5.5 4.5-9.94 10-10l.24-.04z'
    />
    <path
      fill='#8F9FEA'
      d='M209.39 142.11c-3.85-4.02-10.22-4.16-14.24-.31l-.01.01c-23.29 22.33-60.28 21.55-82.61-1.74a58.437 58.437 0 0 1-12.92-20.98 19.165 19.165 0 0 1-13.3 5.13h-.17c-2.18.02-4.36-.32-6.43-1 13.01 41.42 57.12 64.44 98.54 51.44a78.596 78.596 0 0 0 30.84-18.25c4.03-3.84 4.19-10.22.35-14.25l-.05-.05z'
    />
  </svg>
)
export { Logo }
