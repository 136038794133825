import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { MenuIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'wouter'
import { Isotipo } from '../icons/Isotipo'
import { SideNav } from './Navbar'

export const MobileSidebar = () => {
  const [open, setOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <div className='flex items-center justify-center gap-2 md:!hidden'>
          <MenuIcon />
          <span className='self-center text-2xl title font-semibold whitespace-nowrap text-primary dark:text-csecondary'>
            Lira
          </span>
        </div>
      </SheetTrigger>
      <SheetContent side='left' className='w-72 dark:border-slate-800'>
        <div className='px-1 py-6 pt-16'>
          <Link href='/' className='items-center gap-3 ms-2 md:me-24 mb-4 flex'>
            <div className='border rounded-full p-2 dark:border-dark-border'>
              <Isotipo size={30} />
            </div>
            <span className='self-center text-xl title font-semibold sm:text-4xl whitespace-nowrap text-primary dark:text-slate-400'>
              Lira
            </span>
          </Link>
          <SideNav setOpen={setOpen} />
        </div>
      </SheetContent>
    </Sheet>
  )
}