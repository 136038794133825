// src/cognitoService.ts

import { app } from '@/firebase'
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import { FirebaseError } from 'firebase/app'
import { OAuthProvider, User as UserFirebase, getAuth, signInWithCredential } from 'firebase/auth'
import awsConfig from './config.json'

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId
})

export const authenticateUser = async (username: string, password: string): Promise<{ idToken: string; refreshToken: string }> => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  })

  const userData = {
    Username: username,
    Pool: userPool
  }

  const cognitoUser = new CognitoUser(userData)

  return new Promise<{ idToken: string; refreshToken: string }>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: CognitoUserSession) => {
        const idToken = result.getIdToken().getJwtToken()
        const refreshToken = result.getRefreshToken().getToken()
        resolve({ idToken, refreshToken })
      },
      onFailure: (err) => {
        reject(err)
      }
    })
  })
}

function getCognitoUserFromIdToken(idToken: string): CognitoUser | null {
  try {
    const payload = parseJwt(idToken)
    if (!payload) {
      console.error('Failed to parse ID token: Invalid payload')
      return null
    }

    const username = payload['cognito:username']
    if (!username) {
      console.error('Failed to parse ID token: Username not found in payload', payload)
      return null
    }

    const userData = {
      Username: username,
      Pool: userPool
    }
    return new CognitoUser(userData)
  } catch (e) {
    console.error('Failed to parse ID token', e)
    return null
  }
}

function refreshCognitoToken(idToken: string, refreshToken: string): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserFromIdToken(idToken)
    if (!cognitoUser) {
      return reject(new Error('Failed to create CognitoUser from ID token'))
    }

    const refreshTokenObj = new CognitoRefreshToken({ RefreshToken: refreshToken })

    cognitoUser.refreshSession(refreshTokenObj, (err, session) => {
      if (err) {
        console.error('Failed to refresh session', err)
        return reject(err)
      }
      resolve(session)
    })
  })
}
export async function checkAndRefreshToken() {
  const idToken = JSON.parse(localStorage.getItem('session') ?? '{}')?.state?.idToken
  const refreshToken = JSON.parse(localStorage.getItem('session') ?? '{}')?.state?.refreshToken
  
  if (!idToken || !refreshToken) {
    throw new Error('No tokens found')
  }
  const tokenExpiration = parseJwt(idToken).exp * 1000
  const isTokenExpired = Date.now() > tokenExpiration

  if (isTokenExpired) {
    try {
      const session = await refreshCognitoToken(idToken, refreshToken)
      const newIdToken = session.getIdToken().getJwtToken()
      const newRefreshToken = session.getRefreshToken().getToken()
      const firebaseUser = await loginFirebase({ token: newIdToken })
      const tokenFirebase = await firebaseUser?.getIdToken()
      updateSessionState(newIdToken, newRefreshToken, tokenFirebase)
      return newIdToken
    } catch (err) {
      console.error('Failed to refresh token:', err)
      throw err
    }
  }
  return idToken
}

function updateSessionState (idToken: string, refreshToken: string, tokenFirebase?: string) {
  const session = JSON.parse(localStorage.getItem('session') ?? '{}')
  session.state = {
    ...session.state,
    token: tokenFirebase,
    idToken,
    refreshToken
  }
  localStorage.setItem('session', JSON.stringify(session))
}

// Function to decode JWT token and get expiration time
export function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error('Failed to parse JWT', e)
    return null
  }
}

export const loginFirebase = async ({ token }: { token: string }): Promise<UserFirebase | null> => {
  const auth = getAuth(app)
  try {
    const provider = new OAuthProvider('oidc.firebase-lira-bank-staging')
    const userCredential =  provider.credential({ idToken: token })
    const user = await signInWithCredential(auth, userCredential)
    return user.user
  } catch (error: unknown) {
    const firebaseError = error as FirebaseError
    console.error('Error during Firebase sign-in:', firebaseError.code, firebaseError.message)
    return null
  }
}