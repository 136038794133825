import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { useEffect, useRef, useState } from 'react'
import { Label } from '../ui/label'

type Props = {
  value?: number | null;
  onChange: (value: number) => void;
  className?: string;
  classNameButton?: string;
  disabled?: boolean;
};

const InputMoney = ({ value = null, onChange, className, classNameButton, disabled = false }: Props) => {
  const [formattedValue, setFormattedValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value !== null && value !== 0) {
      setFormattedValue(formatNumber(value))
    } else {
      setFormattedValue('')
    }
  }, [value])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '')
    const numericValue = parseFloat(rawValue)
    const cursorPosition = e.target.selectionStart

    if (!isNaN(numericValue)) {
      const previousFormattedValue = formattedValue
      const caret = cursorPosition || 0

      const newFormattedValue = formatNumber(rawValue)

      // Calculate the new caret position
      const commasBefore = (previousFormattedValue.slice(0, caret).match(/,/g) || []).length
      const commasAfter = (newFormattedValue.slice(0, caret).match(/,/g) || []).length
      const newCaretPosition = caret + (commasAfter - commasBefore)

      setFormattedValue(newFormattedValue)
      onChange(numericValue)

      // Update the cursor position after the DOM has been updated
      window.requestAnimationFrame(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = newCaretPosition
          inputRef.current.selectionEnd = newCaretPosition
        }
      })
    } else {
      setFormattedValue('')
      onChange(0)
    }
  }

  const formatNumber = (value: number | string) => {
    const parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }
  const handleBlur = () => {
    if (formattedValue === '') {
      setFormattedValue('')
      onChange(0)
    } else {
      const rawValue = formattedValue.replace(/,/g, '')
      const numericValue = parseFloat(rawValue)
      setFormattedValue(formatNumber(numericValue))
    }
  }



  return (
    <section className={cn('border border-slate-100 dark:border-slate-800 p-4 rounded-xl bg-white dark:bg-transparent', className)}>
      <Label className={`${disabled && 'text-slate-400'}`}>Enter the amount</Label>
      <div className='relative flex items-center'>
        <span className='absolute left-2 text-slate-400 title text-xl'>
          RD$
        </span>
        <Input
          type='text'
          ref={inputRef}
          className={cn('border-none h-10 ps-12 focus-visible:ring-0 focus-visible:ring-offset-0 text-xl font-bold dark:bg-transparent', classNameButton)}
          disabled={disabled}
          aria-label='Enter the amount'
          value={formattedValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      </div>
    </section>
  )
}

export { InputMoney }

