import { DialogHeader, DialogTitle } from '@/components/ui/dialog'

export const LoadingRequest = ({ productName }: { productName: string }) => {

  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle className='text-slate-800 dark:text-slate-200'>
          Requesting your <span className='text-primary'>{productName}</span>
        </DialogTitle>
      </DialogHeader>
      <div className='flex flex-col items-center w-full'>
        <svg id='Isotipo' className='w-20 py-6 animate-pulse' viewBox='0 0 84.891 82.043'>
          <g id='Group_1441' data-name='Group 1441'>
            <g id='Group_1438' data-name='Group 1438' transform='translate(0 50.576)'>
              <g id='Group_1437' data-name='Group 1437'>
                <path id='Path_1228' data-name='Path 1228' d='M2244.783-1190.747c-24.917,0-38.734-17.94-41.3-21.621a6.255,6.255,0,0,1,1.545-8.71,6.263,6.263,0,0,1,8.718,1.545c1.974,2.838,12.849,16.936,32.454,16.255,15.691-.556,25.385-10.522,29.77-16.409a6.254,6.254,0,0,1,8.751-1.292,6.258,6.258,0,0,1,1.286,8.759c-5.718,7.692-18.439,20.708-39.364,21.445C2246.011-1190.754,2245.393-1190.747,2244.783-1190.747Z' transform='translate(-2202.349 1222.214)' fill='#8f9fea' />
              </g>
            </g>
            <g id='Group_1440' data-name='Group 1440' transform='translate(36.328)'>
              <g id='Group_1439' data-name='Group 1439'>
                <rect id='Rectangle_384' data-name='Rectangle 384' width='12.838' height='49.204' rx='6.419' fill='#443dc4' />
              </g>
            </g>
          </g>
        </svg>
        <span className='text-slate-800 dark:text-slate-200 text-lg'>We are validating some data, please wait...</span>
      </div>

    </>
  )
}

