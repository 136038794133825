import { CSSProperties } from 'react'

type Props = {
  className?: string
  style?: CSSProperties
  size: number
  color?: string
}
const Isotipo = (props: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      viewBox='0 0 500 500'
      width={props.size}
      height={props.size}
      {...props}
    >
      <path
        fill={props.color || '#443DC4'}
        d='M249.9 471.8c-134.7 0-209.4-97-223.3-116.9-10.7-15.3-6.9-36.4 8.4-47.1 15.3-10.7 36.4-6.9 47.1 8.4 10.7 15.3 69.5 91.6 175.5 87.9 84.9-3 137.3-56.9 160.9-88.7 11.1-15 32.3-18.1 47.3-7 15 11.2 18.1 32.3 7 47.3-30.9 41.6-99.7 112-212.8 116-3.4 0-6.8.1-10.1.1z'
        className='st0'
      />
      <path
        fill={props.color || '#8F9FEA'}
        d='M251.6 294.2c-19.2 0-34.7-15.5-34.7-34.7V62.9c0-19.2 15.5-34.7 34.7-34.7 19.2 0 34.7 15.5 34.7 34.7v196.6c0 19.2-15.5 34.7-34.7 34.7z'
        className='st0'
      />
    </svg>
  )
}
export { Isotipo }
