// type Props = {
//     className?: string
//     size?: number
//   }
export const CardsImg = () => {
//   const { size, className } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='258.087' height='305.299' viewBox='0 0 258.087 305.299'>
      <defs>
        <filter id='Rectangle_466' x='32.768' y='5.542' width='200.835' height='268.164' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='9' result='blur'/>
          <feFlood floodColor='#0d2556' floodOpacity='0.502'/>
          <feComposite operator='in' in2='blur'/>
          <feComposite in='SourceGraphic'/>
        </filter>
        <filter id='Rectangle_466-2' x='0' y='28.434' width='214.737' height='276.865' filterUnits='userSpaceOnUse'>
          <feOffset dy='3'/>
          <feGaussianBlur stdDeviation='9' result='blur-2'/>
          <feFlood floodColor='#0d2556' floodOpacity='0.502'/>
          <feComposite operator='in' in2='blur-2'/>
          <feComposite in='SourceGraphic'/>
        </filter>
      </defs>
      <g id='Group_1937' data-name='Group 1937' transform='translate(-62.437 -144.667)'>
        <g id='Group_1892' data-name='Group 1892' transform='translate(111.827 -47.376) rotate(8)' opacity='0.72'>
          <g id='Group_1765' data-name='Group 1765' transform='translate(84.187 192.523)'>
            <g id='Group_1718' data-name='Group 1718' transform='translate(0 0)'>
              <g id='Group_1687' data-name='Group 1687'>
                <rect id='Rectangle_466-3' data-name='Rectangle 466' width='209.419' height='139.612' rx='7.2' transform='matrix(-0.087, 0.996, -0.996, -0.087, 153.579, -0.294)' fill='#d7df21'/>
              </g>
              <g id='Group_1690' data-name='Group 1690' transform='translate(94.801 29.345)'>
                <g id='Group_1689' data-name='Group 1689' transform='translate(0)'>
                  <g id='Group_1688' data-name='Group 1688'>
                    <path id='Path_1360' data-name='Path 1360' d='M-64.631,43.125a11.919,11.919,0,0,1-2.411,7.048.646.646,0,0,1-.982.217.683.683,0,0,1-.117-1.057,10.365,10.365,0,0,0,2.084-5.639A10.233,10.233,0,0,0-68.1,37.006c-.286-.4-.309-.747-.061-1a.659.659,0,0,1,1.1.075A11.923,11.923,0,0,1-64.631,43.125Z' transform='translate(74.751 -35.748)' fill='#d7df21'/>
                    <path id='Path_1361' data-name='Path 1361' d='M-67.774,43.47a9.589,9.589,0,0,1-1.89,5.52c-.367.5-.718.562-1.109.222-.33-.286-.323-.61.011-1.073a8.148,8.148,0,0,0,1.508-3.728,7.965,7.965,0,0,0-1.438-5.7,3.785,3.785,0,0,1-.236-.373.615.615,0,0,1,.237-.862.538.538,0,0,1,.8.045,9.341,9.341,0,0,1,.919,1.331A9.532,9.532,0,0,1-67.774,43.47Z' transform='translate(75.231 -36.03)' fill='#d7df21'/>
                    <path id='Path_1362' data-name='Path 1362' d='M-70.807,43.672A7.932,7.932,0,0,1-72.1,48.1a.656.656,0,0,1-.9.241.68.68,0,0,1-.286-.947,15.253,15.253,0,0,0,.835-1.84,5.756,5.756,0,0,0-.885-5.093,1.039,1.039,0,0,1-.2-.731,1,1,0,0,1,.553-.51c.28-.122.513.084.683.306A7.532,7.532,0,0,1-70.807,43.672Z' transform='translate(75.687 -36.363)' fill='#d7df21'/>
                    <path id='Path_1363' data-name='Path 1363' d='M-75.195,44.285a3.844,3.844,0,0,0-.622-2.107c-.071-.115-.149-.226-.214-.345a.645.645,0,0,1,.222-.951.661.661,0,0,1,.937.206,5.311,5.311,0,0,1,.3,5.969.683.683,0,0,1-.937.326.688.688,0,0,1-.254-1.042A3.861,3.861,0,0,0-75.195,44.285Z' transform='translate(76.151 -36.647)' fill='#d7df21'/>
                  </g>
                </g>
              </g>
              <g id='Group_1716' data-name='Group 1716' transform='translate(7.229 171.01)'>
                <g id='Group_1714' data-name='Group 1714'>
                  <g id='Group_1713' data-name='Group 1713'>
                    <g id='Group_1712' data-name='Group 1712'>
                      <g id='Group_1711' data-name='Group 1711'>
                        <g id='Group_1710' data-name='Group 1710'>
                          <g id='Group_1709' data-name='Group 1709'>
                            <g id='Group_1708' data-name='Group 1708'>
                              <g id='Group_1707' data-name='Group 1707'>
                                <g id='Group_1706' data-name='Group 1706'>
                                  <g id='Group_1705' data-name='Group 1705'>
                                    <g id='Group_1701' data-name='Group 1701'>
                                      <g id='Group_1700' data-name='Group 1700'>
                                        <g id='Group_1699' data-name='Group 1699'>
                                          <g id='Group_1692' data-name='Group 1692'>
                                            <g id='Group_1691' data-name='Group 1691'>
                                              <path id='Path_1364' data-name='Path 1364' d='M-180.079,221.784a1.3,1.3,0,0,0,.393.955,1.3,1.3,0,0,0,.955.393h3.271a1.317,1.317,0,0,1,.966.4,1.319,1.319,0,0,1,.4.967v.022a1.258,1.258,0,0,1-.4.956,1.317,1.317,0,0,1-.966.4h-3.271a3.951,3.951,0,0,1-2.9-1.2,3.953,3.953,0,0,1-1.2-2.9v-12.1a1.317,1.317,0,0,1,.4-.966,1.322,1.322,0,0,1,.955-.394h.034a1.325,1.325,0,0,1,.955.394,1.32,1.32,0,0,1,.4.966Z' transform='translate(182.833 -208.328)' fill='#d7df21'/>
                                            </g>
                                          </g>
                                          <g id='Group_1694' data-name='Group 1694' transform='translate(16.842 4.609)'>
                                            <g id='Group_1693' data-name='Group 1693'>
                                              <path id='Path_1365' data-name='Path 1365' d='M-156.718,213.943a1.32,1.32,0,0,1,.967.4,1.338,1.338,0,0,1,.393.966v.023a1.3,1.3,0,0,1-.393.955,1.323,1.323,0,0,1-.967.4h-.641a2.126,2.126,0,0,0-1.562.64,2.184,2.184,0,0,0-.641,1.563v3.878a1.258,1.258,0,0,1-.4.955,1.3,1.3,0,0,1-.955.4h-.034a1.307,1.307,0,0,1-.956-.4,1.261,1.261,0,0,1-.4-.955V218.9a4.9,4.9,0,0,1,1.45-3.508,4.78,4.78,0,0,1,3.507-1.45Z' transform='translate(162.316 -213.943)' fill='#d7df21'/>
                                            </g>
                                          </g>
                                          <g id='Group_1696' data-name='Group 1696' transform='translate(24.021 4.609)'>
                                            <g id='Group_1695' data-name='Group 1695'>
                                              <path id='Path_1366' data-name='Path 1366' d='M-142.52,215.842a6.239,6.239,0,0,1,1.9,4.575v5.115a1.261,1.261,0,0,1-.4.955,1.306,1.306,0,0,1-.955.4h-.034a1.3,1.3,0,0,1-.955-.4,1.258,1.258,0,0,1-.4-.955,4.868,4.868,0,0,1-3.721,1.36,6.233,6.233,0,0,1-4.575-1.9,6.237,6.237,0,0,1-1.9-4.576,6.237,6.237,0,0,1,1.9-4.575,6.233,6.233,0,0,1,4.575-1.9A6.236,6.236,0,0,1-142.52,215.842Zm-7.217,1.945a3.606,3.606,0,0,0-1.079,2.63,3.62,3.62,0,0,0,1.079,2.642,3.616,3.616,0,0,0,2.642,1.079,3.579,3.579,0,0,0,2.63-1.079,3.6,3.6,0,0,0,1.091-2.642,3.584,3.584,0,0,0-1.091-2.63,3.584,3.584,0,0,0-2.63-1.09A3.6,3.6,0,0,0-149.737,217.787Z' transform='translate(153.57 -213.943)' fill='#d7df21'/>
                                            </g>
                                          </g>
                                          <g id='Group_1697' data-name='Group 1697' transform='translate(10.201)' opacity='0.6'>
                                            <path id='Path_1367' data-name='Path 1367' d='M-168.832,211.465a1.514,1.514,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.514,1.514,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.463,1.463,0,0,1,.472,1.1,1.474,1.474,0,0,1-.472,1.112A1.5,1.5,0,0,1-168.832,211.465Z' transform='translate(170.406 -208.328)' fill='#d7df21'/>
                                          </g>
                                          <g id='Group_1698' data-name='Group 1698' transform='translate(28.922 9.515)' opacity='0.6'>
                                            <path id='Path_1368' data-name='Path 1368' d='M-146.025,223.057a1.517,1.517,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.517,1.517,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.46,1.46,0,0,1,.472,1.1,1.473,1.473,0,0,1-.472,1.112A1.5,1.5,0,0,1-146.025,223.057Z' transform='translate(147.599 -219.92)' fill='#d7df21'/>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g id='Group_1703' data-name='Group 1703' transform='translate(10.397 4.609)'>
                                      <g id='Group_1702' data-name='Group 1702'>
                                        <path id='Path_1369' data-name='Path 1369' d='M-168.773,213.943a1.258,1.258,0,0,1,.955.4,1.317,1.317,0,0,1,.4.966v7.464a1.258,1.258,0,0,1-.4.955,1.258,1.258,0,0,1-.955.4h-.022a1.319,1.319,0,0,1-.967-.4,1.261,1.261,0,0,1-.4-.955v-7.464a1.32,1.32,0,0,1,.4-.966,1.319,1.319,0,0,1,.967-.4Z' transform='translate(170.167 -213.943)' fill='#d7df21'/>
                                      </g>
                                    </g>
                                    <g id='Group_1704' data-name='Group 1704' transform='translate(10.89 16.27)' opacity='0.6'>
                                      <path id='Path_1370' data-name='Path 1370' d='M-151.849,231.294a1.377,1.377,0,0,0-1.947-.041,7.948,7.948,0,0,1-5.524,2.22,8,8,0,0,1-7.527-5.325,2.619,2.619,0,0,1-1.818.7h-.022a2.74,2.74,0,0,1-.879-.142,10.757,10.757,0,0,0,10.246,7.521,10.689,10.689,0,0,0,7.43-2.986A1.378,1.378,0,0,0-151.849,231.294Z' transform='translate(169.567 -228.148)' fill='#d7df21'/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id='Group_1715' data-name='Group 1715' transform='translate(111.418 12.501)'>
                  <g id='Money_Visa' data-name='Money Visa' transform='translate(0)'>
                    <g id='icons8-visa'>
                      <g id='Group'>
                        <path id='Vector-3' d='M-47.1,223.557' transform='translate(47.1 -223.557)' fill='#d7df21'/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id='Group_1717' data-name='Group 1717' transform='translate(67.138 23.951)'>
                <path id='Path_1371' data-name='Path 1371' d='M-101.32,37.765h2.858V29.177h-5.608v5.011a1.086,1.086,0,0,0,.267.713Z' transform='translate(108.815 -29.177)' fill='#d7df21'/>
                <path id='Path_1372' data-name='Path 1372' d='M-103.024,38.095l-2.36-2.72a1.809,1.809,0,0,1-.442-1.186V29.177h-1.279a2.746,2.746,0,0,0-2.746,2.745V41.6h6.827Z' transform='translate(109.851 -29.177)' fill='#d7df21'/>
                <path id='Path_1373' data-name='Path 1373' d='M-90.917,34.9a1.09,1.09,0,0,0,.267-.713V29.177h-5.71v8.588h2.96Z' transform='translate(107.434 -29.177)' fill='#d7df21'/>
                <path id='Path_1374' data-name='Path 1374' d='M-87.973,29.177H-89.16v5.011a1.811,1.811,0,0,1-.442,1.186l-2.461,2.836V41.6h6.835V31.922A2.745,2.745,0,0,0-87.973,29.177Z' transform='translate(106.665 -29.177)' fill='#d7df21'/>
                <path id='Path_1375' data-name='Path 1375' d='M-93.235,49.779H-96.36v8.663h5.71V53.165a1.094,1.094,0,0,0-.267-.714Z' transform='translate(107.434 -32.868)' fill='#d7df21'/>
                <path id='Path_1376' data-name='Path 1376' d='M-92.063,48.322l2.46,2.836a1.807,1.807,0,0,1,.443,1.186v5.277h1.187a2.746,2.746,0,0,0,2.745-2.746V45.194h-6.835Z' transform='translate(106.665 -32.046)' fill='#d7df21'/>
                <path id='Path_1377' data-name='Path 1377' d='M-103.024,45.194h-6.827v9.681a2.746,2.746,0,0,0,2.746,2.746h1.279V52.344a1.806,1.806,0,0,1,.443-1.186l2.359-2.72Z' transform='translate(109.851 -32.046)' fill='#d7df21'/>
                <path id='Path_1378' data-name='Path 1378' d='M-103.8,52.451a1.09,1.09,0,0,0-.267.714v5.277h5.608V49.779h-3.023Z' transform='translate(108.815 -32.868)' fill='#d7df21'/>
                <rect id='Rectangle_467' data-name='Rectangle 467' width='6.558' height='7.111' rx='0.414' transform='translate(7.44 9.232)' fill='#d7df21'/>
              </g>
            </g>
          </g>
        </g>
        <g id='Group_1890' data-name='Group 1890' transform='translate(52.097 -21.135) rotate(2)' opacity='0.72'>
          <g id='Group_1765-2' data-name='Group 1765' transform='translate(84.187 192.523)'>
            <g id='Group_1718-2' data-name='Group 1718' transform='translate(0 0)'>
              <g id='Group_1687-2' data-name='Group 1687'>
                <g transform='matrix(1, -0.03, 0.03, 1, -68.07, -27.18)' filter='url(#Rectangle_466)'>
                  <rect id='Rectangle_466-4' data-name='Rectangle 466' width='209.419' height='139.612' rx='7.2' transform='translate(206.6 34.41) rotate(92)' fill='#43d6dd'/>
                </g>
              </g>
              <g id='Group_1690-2' data-name='Group 1690' transform='translate(94.801 29.345)'>
                <g id='Group_1689-2' data-name='Group 1689' transform='translate(0)'>
                  <g id='Group_1688-2' data-name='Group 1688'>
                    <path id='Path_1360-2' data-name='Path 1360' d='M-64.631,43.125a11.919,11.919,0,0,1-2.411,7.048.646.646,0,0,1-.982.217.683.683,0,0,1-.117-1.057,10.365,10.365,0,0,0,2.084-5.639A10.233,10.233,0,0,0-68.1,37.006c-.286-.4-.309-.747-.061-1a.659.659,0,0,1,1.1.075A11.923,11.923,0,0,1-64.631,43.125Z' transform='translate(74.751 -35.748)' fill='#43d6dd'/>
                    <path id='Path_1361-2' data-name='Path 1361' d='M-67.774,43.47a9.589,9.589,0,0,1-1.89,5.52c-.367.5-.718.562-1.109.222-.33-.286-.323-.61.011-1.073a8.148,8.148,0,0,0,1.508-3.728,7.965,7.965,0,0,0-1.438-5.7,3.785,3.785,0,0,1-.236-.373.615.615,0,0,1,.237-.862.538.538,0,0,1,.8.045,9.341,9.341,0,0,1,.919,1.331A9.532,9.532,0,0,1-67.774,43.47Z' transform='translate(75.231 -36.03)' fill='#43d6dd'/>
                    <path id='Path_1362-2' data-name='Path 1362' d='M-70.807,43.672A7.932,7.932,0,0,1-72.1,48.1a.656.656,0,0,1-.9.241.68.68,0,0,1-.286-.947,15.253,15.253,0,0,0,.835-1.84,5.756,5.756,0,0,0-.885-5.093,1.039,1.039,0,0,1-.2-.731,1,1,0,0,1,.553-.51c.28-.122.513.084.683.306A7.532,7.532,0,0,1-70.807,43.672Z' transform='translate(75.687 -36.363)' fill='#43d6dd'/>
                    <path id='Path_1363-2' data-name='Path 1363' d='M-75.195,44.285a3.844,3.844,0,0,0-.622-2.107c-.071-.115-.149-.226-.214-.345a.645.645,0,0,1,.222-.951.661.661,0,0,1,.937.206,5.311,5.311,0,0,1,.3,5.969.683.683,0,0,1-.937.326.688.688,0,0,1-.254-1.042A3.861,3.861,0,0,0-75.195,44.285Z' transform='translate(76.151 -36.647)' fill='#43d6dd'/>
                  </g>
                </g>
              </g>
              <g id='Group_1716-2' data-name='Group 1716' transform='translate(7.229 171.01)'>
                <g id='Group_1714-2' data-name='Group 1714'>
                  <g id='Group_1713-2' data-name='Group 1713'>
                    <g id='Group_1712-2' data-name='Group 1712'>
                      <g id='Group_1711-2' data-name='Group 1711'>
                        <g id='Group_1710-2' data-name='Group 1710'>
                          <g id='Group_1709-2' data-name='Group 1709'>
                            <g id='Group_1708-2' data-name='Group 1708'>
                              <g id='Group_1707-2' data-name='Group 1707'>
                                <g id='Group_1706-2' data-name='Group 1706'>
                                  <g id='Group_1705-2' data-name='Group 1705'>
                                    <g id='Group_1701-2' data-name='Group 1701'>
                                      <g id='Group_1700-2' data-name='Group 1700'>
                                        <g id='Group_1699-2' data-name='Group 1699'>
                                          <g id='Group_1692-2' data-name='Group 1692'>
                                            <g id='Group_1691-2' data-name='Group 1691'>
                                              <path id='Path_1364-2' data-name='Path 1364' d='M-180.079,221.784a1.3,1.3,0,0,0,.393.955,1.3,1.3,0,0,0,.955.393h3.271a1.317,1.317,0,0,1,.966.4,1.319,1.319,0,0,1,.4.967v.022a1.258,1.258,0,0,1-.4.956,1.317,1.317,0,0,1-.966.4h-3.271a3.951,3.951,0,0,1-2.9-1.2,3.953,3.953,0,0,1-1.2-2.9v-12.1a1.317,1.317,0,0,1,.4-.966,1.322,1.322,0,0,1,.955-.394h.034a1.325,1.325,0,0,1,.955.394,1.32,1.32,0,0,1,.4.966Z' transform='translate(182.833 -208.328)' fill='#43d6dd'/>
                                            </g>
                                          </g>
                                          <g id='Group_1694-2' data-name='Group 1694' transform='translate(16.842 4.609)'>
                                            <g id='Group_1693-2' data-name='Group 1693'>
                                              <path id='Path_1365-2' data-name='Path 1365' d='M-156.718,213.943a1.32,1.32,0,0,1,.967.4,1.338,1.338,0,0,1,.393.966v.023a1.3,1.3,0,0,1-.393.955,1.323,1.323,0,0,1-.967.4h-.641a2.126,2.126,0,0,0-1.562.64,2.184,2.184,0,0,0-.641,1.563v3.878a1.258,1.258,0,0,1-.4.955,1.3,1.3,0,0,1-.955.4h-.034a1.307,1.307,0,0,1-.956-.4,1.261,1.261,0,0,1-.4-.955V218.9a4.9,4.9,0,0,1,1.45-3.508,4.78,4.78,0,0,1,3.507-1.45Z' transform='translate(162.316 -213.943)' fill='#43d6dd'/>
                                            </g>
                                          </g>
                                          <g id='Group_1696-2' data-name='Group 1696' transform='translate(24.021 4.609)'>
                                            <g id='Group_1695-2' data-name='Group 1695'>
                                              <path id='Path_1366-2' data-name='Path 1366' d='M-142.52,215.842a6.239,6.239,0,0,1,1.9,4.575v5.115a1.261,1.261,0,0,1-.4.955,1.306,1.306,0,0,1-.955.4h-.034a1.3,1.3,0,0,1-.955-.4,1.258,1.258,0,0,1-.4-.955,4.868,4.868,0,0,1-3.721,1.36,6.233,6.233,0,0,1-4.575-1.9,6.237,6.237,0,0,1-1.9-4.576,6.237,6.237,0,0,1,1.9-4.575,6.233,6.233,0,0,1,4.575-1.9A6.236,6.236,0,0,1-142.52,215.842Zm-7.217,1.945a3.606,3.606,0,0,0-1.079,2.63,3.62,3.62,0,0,0,1.079,2.642,3.616,3.616,0,0,0,2.642,1.079,3.579,3.579,0,0,0,2.63-1.079,3.6,3.6,0,0,0,1.091-2.642,3.584,3.584,0,0,0-1.091-2.63,3.584,3.584,0,0,0-2.63-1.09A3.6,3.6,0,0,0-149.737,217.787Z' transform='translate(153.57 -213.943)' fill='#43d6dd'/>
                                            </g>
                                          </g>
                                          <g id='Group_1697-2' data-name='Group 1697' transform='translate(10.201)' opacity='0.6'>
                                            <path id='Path_1367-2' data-name='Path 1367' d='M-168.832,211.465a1.514,1.514,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.514,1.514,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.463,1.463,0,0,1,.472,1.1,1.474,1.474,0,0,1-.472,1.112A1.5,1.5,0,0,1-168.832,211.465Z' transform='translate(170.406 -208.328)' fill='#43d6dd'/>
                                          </g>
                                          <g id='Group_1698-2' data-name='Group 1698' transform='translate(28.922 9.515)' opacity='0.6'>
                                            <path id='Path_1368-2' data-name='Path 1368' d='M-146.025,223.057a1.517,1.517,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.517,1.517,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.46,1.46,0,0,1,.472,1.1,1.473,1.473,0,0,1-.472,1.112A1.5,1.5,0,0,1-146.025,223.057Z' transform='translate(147.599 -219.92)' fill='#43d6dd'/>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g id='Group_1703-2' data-name='Group 1703' transform='translate(10.397 4.609)'>
                                      <g id='Group_1702-2' data-name='Group 1702'>
                                        <path id='Path_1369-2' data-name='Path 1369' d='M-168.773,213.943a1.258,1.258,0,0,1,.955.4,1.317,1.317,0,0,1,.4.966v7.464a1.258,1.258,0,0,1-.4.955,1.258,1.258,0,0,1-.955.4h-.022a1.319,1.319,0,0,1-.967-.4,1.261,1.261,0,0,1-.4-.955v-7.464a1.32,1.32,0,0,1,.4-.966,1.319,1.319,0,0,1,.967-.4Z' transform='translate(170.167 -213.943)' fill='#43d6dd'/>
                                      </g>
                                    </g>
                                    <g id='Group_1704-2' data-name='Group 1704' transform='translate(10.89 16.27)' opacity='0.6'>
                                      <path id='Path_1370-2' data-name='Path 1370' d='M-151.849,231.294a1.377,1.377,0,0,0-1.947-.041,7.948,7.948,0,0,1-5.524,2.22,8,8,0,0,1-7.527-5.325,2.619,2.619,0,0,1-1.818.7h-.022a2.74,2.74,0,0,1-.879-.142,10.757,10.757,0,0,0,10.246,7.521,10.689,10.689,0,0,0,7.43-2.986A1.378,1.378,0,0,0-151.849,231.294Z' transform='translate(169.567 -228.148)' fill='#43d6dd'/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id='Group_1715-2' data-name='Group 1715' transform='translate(111.418 12.501)'>
                  <g id='Money_Visa-2' data-name='Money Visa' transform='translate(0)'>
                    <g id='icons8-visa-2' data-name='icons8-visa'>
                      <g id='Group-2' data-name='Group'>
                        <path id='Vector-3-2' data-name='Vector-3' d='M-47.1,223.557' transform='translate(47.1 -223.557)' fill='#43d6dd'/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id='Group_1717-2' data-name='Group 1717' transform='translate(67.138 23.951)'>
                <path id='Path_1371-2' data-name='Path 1371' d='M-101.32,37.765h2.858V29.177h-5.608v5.011a1.086,1.086,0,0,0,.267.713Z' transform='translate(108.815 -29.177)' fill='#43d6dd'/>
                <path id='Path_1372-2' data-name='Path 1372' d='M-103.024,38.095l-2.36-2.72a1.809,1.809,0,0,1-.442-1.186V29.177h-1.279a2.746,2.746,0,0,0-2.746,2.745V41.6h6.827Z' transform='translate(109.851 -29.177)' fill='#43d6dd'/>
                <path id='Path_1373-2' data-name='Path 1373' d='M-90.917,34.9a1.09,1.09,0,0,0,.267-.713V29.177h-5.71v8.588h2.96Z' transform='translate(107.434 -29.177)' fill='#43d6dd'/>
                <path id='Path_1374-2' data-name='Path 1374' d='M-87.973,29.177H-89.16v5.011a1.811,1.811,0,0,1-.442,1.186l-2.461,2.836V41.6h6.835V31.922A2.745,2.745,0,0,0-87.973,29.177Z' transform='translate(106.665 -29.177)' fill='#43d6dd'/>
                <path id='Path_1375-2' data-name='Path 1375' d='M-93.235,49.779H-96.36v8.663h5.71V53.165a1.094,1.094,0,0,0-.267-.714Z' transform='translate(107.434 -32.868)' fill='#43d6dd'/>
                <path id='Path_1376-2' data-name='Path 1376' d='M-92.063,48.322l2.46,2.836a1.807,1.807,0,0,1,.443,1.186v5.277h1.187a2.746,2.746,0,0,0,2.745-2.746V45.194h-6.835Z' transform='translate(106.665 -32.046)' fill='#43d6dd'/>
                <path id='Path_1377-2' data-name='Path 1377' d='M-103.024,45.194h-6.827v9.681a2.746,2.746,0,0,0,2.746,2.746h1.279V52.344a1.806,1.806,0,0,1,.443-1.186l2.359-2.72Z' transform='translate(109.851 -32.046)' fill='#43d6dd'/>
                <path id='Path_1378-2' data-name='Path 1378' d='M-103.8,52.451a1.09,1.09,0,0,0-.267.714v5.277h5.608V49.779h-3.023Z' transform='translate(108.815 -32.868)' fill='#43d6dd'/>
                <rect id='Rectangle_467-2' data-name='Rectangle 467' width='6.558' height='7.111' rx='0.414' transform='translate(7.44 9.232)' fill='#43d6dd'/>
              </g>
            </g>
          </g>
        </g>
        <g id='Group_1891' data-name='Group 1891' transform='matrix(0.995, -0.105, 0.105, 0.995, -14.412, 29.025)'>
          <g id='Group_1765-3' data-name='Group 1765' transform='translate(84.187 192.523)'>
            <g id='Group_1718-3' data-name='Group 1718' transform='translate(0 0)'>
              <g id='Group_1687-3' data-name='Group 1687'>
                <g transform='matrix(0.99, 0.1, -0.1, 0.99, -19.85, -69.48)' filter='url(#Rectangle_466-2)'>
                  <rect id='Rectangle_466-5' data-name='Rectangle 466' width='209.419' height='139.612' rx='7.2' transform='matrix(0.1, 0.99, -0.99, 0.1, 165.85, 52.43)' fill='#443dc4'/>
                </g>
              </g>
              <g id='Group_1690-3' data-name='Group 1690' transform='translate(94.801 29.345)'>
                <g id='Group_1689-3' data-name='Group 1689' transform='translate(0)'>
                  <g id='Group_1688-3' data-name='Group 1688'>
                    <path id='Path_1360-3' data-name='Path 1360' d='M-64.631,43.125a11.919,11.919,0,0,1-2.411,7.048.646.646,0,0,1-.982.217.683.683,0,0,1-.117-1.057,10.365,10.365,0,0,0,2.084-5.639A10.233,10.233,0,0,0-68.1,37.006c-.286-.4-.309-.747-.061-1a.659.659,0,0,1,1.1.075A11.923,11.923,0,0,1-64.631,43.125Z' transform='translate(74.751 -35.748)' fill='#fff'/>
                    <path id='Path_1361-3' data-name='Path 1361' d='M-67.774,43.47a9.589,9.589,0,0,1-1.89,5.52c-.367.5-.718.562-1.109.222-.33-.286-.323-.61.011-1.073a8.148,8.148,0,0,0,1.508-3.728,7.965,7.965,0,0,0-1.438-5.7,3.785,3.785,0,0,1-.236-.373.615.615,0,0,1,.237-.862.538.538,0,0,1,.8.045,9.341,9.341,0,0,1,.919,1.331A9.532,9.532,0,0,1-67.774,43.47Z' transform='translate(75.231 -36.03)' fill='#fff'/>
                    <path id='Path_1362-3' data-name='Path 1362' d='M-70.807,43.672A7.932,7.932,0,0,1-72.1,48.1a.656.656,0,0,1-.9.241.68.68,0,0,1-.286-.947,15.253,15.253,0,0,0,.835-1.84,5.756,5.756,0,0,0-.885-5.093,1.039,1.039,0,0,1-.2-.731,1,1,0,0,1,.553-.51c.28-.122.513.084.683.306A7.532,7.532,0,0,1-70.807,43.672Z' transform='translate(75.687 -36.363)' fill='#fff'/>
                    <path id='Path_1363-3' data-name='Path 1363' d='M-75.195,44.285a3.844,3.844,0,0,0-.622-2.107c-.071-.115-.149-.226-.214-.345a.645.645,0,0,1,.222-.951.661.661,0,0,1,.937.206,5.311,5.311,0,0,1,.3,5.969.683.683,0,0,1-.937.326.688.688,0,0,1-.254-1.042A3.861,3.861,0,0,0-75.195,44.285Z' transform='translate(76.151 -36.647)' fill='#fff'/>
                  </g>
                </g>
              </g>
              <g id='Group_1716-3' data-name='Group 1716' transform='translate(7.229 171.01)'>
                <g id='Group_1714-3' data-name='Group 1714'>
                  <g id='Group_1713-3' data-name='Group 1713'>
                    <g id='Group_1712-3' data-name='Group 1712'>
                      <g id='Group_1711-3' data-name='Group 1711'>
                        <g id='Group_1710-3' data-name='Group 1710'>
                          <g id='Group_1709-3' data-name='Group 1709'>
                            <g id='Group_1708-3' data-name='Group 1708'>
                              <g id='Group_1707-3' data-name='Group 1707'>
                                <g id='Group_1706-3' data-name='Group 1706'>
                                  <g id='Group_1705-3' data-name='Group 1705'>
                                    <g id='Group_1701-3' data-name='Group 1701'>
                                      <g id='Group_1700-3' data-name='Group 1700'>
                                        <g id='Group_1699-3' data-name='Group 1699'>
                                          <g id='Group_1692-3' data-name='Group 1692'>
                                            <g id='Group_1691-3' data-name='Group 1691'>
                                              <path id='Path_1364-3' data-name='Path 1364' d='M-180.079,221.784a1.3,1.3,0,0,0,.393.955,1.3,1.3,0,0,0,.955.393h3.271a1.317,1.317,0,0,1,.966.4,1.319,1.319,0,0,1,.4.967v.022a1.258,1.258,0,0,1-.4.956,1.317,1.317,0,0,1-.966.4h-3.271a3.951,3.951,0,0,1-2.9-1.2,3.953,3.953,0,0,1-1.2-2.9v-12.1a1.317,1.317,0,0,1,.4-.966,1.322,1.322,0,0,1,.955-.394h.034a1.325,1.325,0,0,1,.955.394,1.32,1.32,0,0,1,.4.966Z' transform='translate(182.833 -208.328)' fill='#fff'/>
                                            </g>
                                          </g>
                                          <g id='Group_1694-3' data-name='Group 1694' transform='translate(16.842 4.609)'>
                                            <g id='Group_1693-3' data-name='Group 1693'>
                                              <path id='Path_1365-3' data-name='Path 1365' d='M-156.718,213.943a1.32,1.32,0,0,1,.967.4,1.338,1.338,0,0,1,.393.966v.023a1.3,1.3,0,0,1-.393.955,1.323,1.323,0,0,1-.967.4h-.641a2.126,2.126,0,0,0-1.562.64,2.184,2.184,0,0,0-.641,1.563v3.878a1.258,1.258,0,0,1-.4.955,1.3,1.3,0,0,1-.955.4h-.034a1.307,1.307,0,0,1-.956-.4,1.261,1.261,0,0,1-.4-.955V218.9a4.9,4.9,0,0,1,1.45-3.508,4.78,4.78,0,0,1,3.507-1.45Z' transform='translate(162.316 -213.943)' fill='#fff'/>
                                            </g>
                                          </g>
                                          <g id='Group_1696-3' data-name='Group 1696' transform='translate(24.021 4.609)'>
                                            <g id='Group_1695-3' data-name='Group 1695'>
                                              <path id='Path_1366-3' data-name='Path 1366' d='M-142.52,215.842a6.239,6.239,0,0,1,1.9,4.575v5.115a1.261,1.261,0,0,1-.4.955,1.306,1.306,0,0,1-.955.4h-.034a1.3,1.3,0,0,1-.955-.4,1.258,1.258,0,0,1-.4-.955,4.868,4.868,0,0,1-3.721,1.36,6.233,6.233,0,0,1-4.575-1.9,6.237,6.237,0,0,1-1.9-4.576,6.237,6.237,0,0,1,1.9-4.575,6.233,6.233,0,0,1,4.575-1.9A6.236,6.236,0,0,1-142.52,215.842Zm-7.217,1.945a3.606,3.606,0,0,0-1.079,2.63,3.62,3.62,0,0,0,1.079,2.642,3.616,3.616,0,0,0,2.642,1.079,3.579,3.579,0,0,0,2.63-1.079,3.6,3.6,0,0,0,1.091-2.642,3.584,3.584,0,0,0-1.091-2.63,3.584,3.584,0,0,0-2.63-1.09A3.6,3.6,0,0,0-149.737,217.787Z' transform='translate(153.57 -213.943)' fill='#fff'/>
                                            </g>
                                          </g>
                                          <g id='Group_1697-3' data-name='Group 1697' transform='translate(10.201)' opacity='0.6'>
                                            <path id='Path_1367-3' data-name='Path 1367' d='M-168.832,211.465a1.514,1.514,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.514,1.514,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.463,1.463,0,0,1,.472,1.1,1.474,1.474,0,0,1-.472,1.112A1.5,1.5,0,0,1-168.832,211.465Z' transform='translate(170.406 -208.328)' fill='#fff'/>
                                          </g>
                                          <g id='Group_1698-3' data-name='Group 1698' transform='translate(28.922 9.515)' opacity='0.6'>
                                            <path id='Path_1368-3' data-name='Path 1368' d='M-146.025,223.057a1.517,1.517,0,0,1-1.113-.461,1.516,1.516,0,0,1-.461-1.112,1.5,1.5,0,0,1,.461-1.1,1.517,1.517,0,0,1,1.113-.461,1.5,1.5,0,0,1,1.1.461,1.46,1.46,0,0,1,.472,1.1,1.473,1.473,0,0,1-.472,1.112A1.5,1.5,0,0,1-146.025,223.057Z' transform='translate(147.599 -219.92)' fill='#fff'/>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g id='Group_1703-3' data-name='Group 1703' transform='translate(10.397 4.609)'>
                                      <g id='Group_1702-3' data-name='Group 1702'>
                                        <path id='Path_1369-3' data-name='Path 1369' d='M-168.773,213.943a1.258,1.258,0,0,1,.955.4,1.317,1.317,0,0,1,.4.966v7.464a1.258,1.258,0,0,1-.4.955,1.258,1.258,0,0,1-.955.4h-.022a1.319,1.319,0,0,1-.967-.4,1.261,1.261,0,0,1-.4-.955v-7.464a1.32,1.32,0,0,1,.4-.966,1.319,1.319,0,0,1,.967-.4Z' transform='translate(170.167 -213.943)' fill='#fff'/>
                                      </g>
                                    </g>
                                    <g id='Group_1704-3' data-name='Group 1704' transform='translate(10.89 16.27)' opacity='0.6'>
                                      <path id='Path_1370-3' data-name='Path 1370' d='M-151.849,231.294a1.377,1.377,0,0,0-1.947-.041,7.948,7.948,0,0,1-5.524,2.22,8,8,0,0,1-7.527-5.325,2.619,2.619,0,0,1-1.818.7h-.022a2.74,2.74,0,0,1-.879-.142,10.757,10.757,0,0,0,10.246,7.521,10.689,10.689,0,0,0,7.43-2.986A1.378,1.378,0,0,0-151.849,231.294Z' transform='translate(169.567 -228.148)' fill='#fff'/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id='Group_1715-3' data-name='Group 1715' transform='translate(111.418 12.501)'>
                  <g id='Money_Visa-3' data-name='Money Visa' transform='translate(0)'>
                    <g id='icons8-visa-3' data-name='icons8-visa'>
                      <g id='Group-3' data-name='Group'>
                        <path id='Vector-3-3' data-name='Vector-3' d='M-47.1,223.557' transform='translate(47.1 -223.557)' fill='#fff'/>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id='Group_1717-3' data-name='Group 1717' transform='translate(67.138 23.951)'>
                <path id='Path_1371-3' data-name='Path 1371' d='M-101.32,37.765h2.858V29.177h-5.608v5.011a1.086,1.086,0,0,0,.267.713Z' transform='translate(108.815 -29.177)' fill='#fff'/>
                <path id='Path_1372-3' data-name='Path 1372' d='M-103.024,38.095l-2.36-2.72a1.809,1.809,0,0,1-.442-1.186V29.177h-1.279a2.746,2.746,0,0,0-2.746,2.745V41.6h6.827Z' transform='translate(109.851 -29.177)' fill='#fff'/>
                <path id='Path_1373-3' data-name='Path 1373' d='M-90.917,34.9a1.09,1.09,0,0,0,.267-.713V29.177h-5.71v8.588h2.96Z' transform='translate(107.434 -29.177)' fill='#fff'/>
                <path id='Path_1374-3' data-name='Path 1374' d='M-87.973,29.177H-89.16v5.011a1.811,1.811,0,0,1-.442,1.186l-2.461,2.836V41.6h6.835V31.922A2.745,2.745,0,0,0-87.973,29.177Z' transform='translate(106.665 -29.177)' fill='#fff'/>
                <path id='Path_1375-3' data-name='Path 1375' d='M-93.235,49.779H-96.36v8.663h5.71V53.165a1.094,1.094,0,0,0-.267-.714Z' transform='translate(107.434 -32.868)' fill='#fff'/>
                <path id='Path_1376-3' data-name='Path 1376' d='M-92.063,48.322l2.46,2.836a1.807,1.807,0,0,1,.443,1.186v5.277h1.187a2.746,2.746,0,0,0,2.745-2.746V45.194h-6.835Z' transform='translate(106.665 -32.046)' fill='#fff'/>
                <path id='Path_1377-3' data-name='Path 1377' d='M-103.024,45.194h-6.827v9.681a2.746,2.746,0,0,0,2.746,2.746h1.279V52.344a1.806,1.806,0,0,1,.443-1.186l2.359-2.72Z' transform='translate(109.851 -32.046)' fill='#fff'/>
                <path id='Path_1378-3' data-name='Path 1378' d='M-103.8,52.451a1.09,1.09,0,0,0-.267.714v5.277h5.608V49.779h-3.023Z' transform='translate(108.815 -32.868)' fill='#fff'/>
                <rect id='Rectangle_467-3' data-name='Rectangle 467' width='6.558' height='7.111' rx='0.414' transform='translate(7.44 9.232)' fill='#fff'/>
              </g>
            </g>
            <g id='Group_1721' data-name='Group 1721' transform='translate(100.475 173.324)'>
              <g id='Group_1720' data-name='Group 1720'>
                <g id='Group_1719' data-name='Group 1719'>
                  <path id='Path_1379' data-name='Path 1379' d='M-53.285,228.756a9.78,9.78,0,0,1-6.094,2.112,9.859,9.859,0,0,1-9.86-9.86,9.859,9.859,0,0,1,9.86-9.861,9.832,9.832,0,0,1,6.094,2.1,9.863,9.863,0,0,0-3.767,7.758A9.831,9.831,0,0,0-53.285,228.756Z' transform='translate(69.239 -211.147)' fill='#eb001b'/>
                  <path id='Path_1380' data-name='Path 1380' d='M-43.71,211.147a9.812,9.812,0,0,0-6.093,2.1,9.862,9.862,0,0,1,3.766,7.758,9.83,9.83,0,0,1-3.766,7.748,9.761,9.761,0,0,0,6.093,2.112,9.865,9.865,0,0,0,9.861-9.86A9.865,9.865,0,0,0-43.71,211.147Z' transform='translate(65.757 -211.147)' fill='#f79e1b'/>
                  <path id='Path_1381' data-name='Path 1381' d='M-46.859,221.467a9.83,9.83,0,0,1-3.766,7.748,9.831,9.831,0,0,1-3.767-7.748,9.863,9.863,0,0,1,3.767-7.758A9.862,9.862,0,0,1-46.859,221.467Z' transform='translate(66.579 -211.606)' fill='#ff5f00'/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}  