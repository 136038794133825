
type Props = {
    className?: string
    size?: number
  }
const ArrowRight = (props: Props) => {
  const { size, className } = props
  return (
    <svg className={className} width={size} height={size} viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' >

      <path d='M0 0h48v48H0z' fill='none' />
      <g id='Shopicon'>
        <polygon points='14.586,9.414 29.171,24 14.586,38.586 17.414,41.414 34.829,24 17.414,6.586 	' />
      </g>
    </svg>
  )
}
export { ArrowRight }
