'use client'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Button } from '../../ui/button'

import { fmCurrency } from '@/lib/constants'
import { PrimaryItemDetail } from '../../common/PrimaryltemDetail'
import { Account } from '@/types/product'

type Props = {
  account?: Account | null;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (value: boolean) => void;
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

const AccountDetails = ({ account, children, open, setOpen }: Props) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children && (
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>)}
      {(account === null && account === undefined) && <DialogContent>
        <DialogTitle>Account Details</DialogTitle>
        <div className='flex justify-center'>Details Not Available. Try Later</div>
        <Button onClick={() => setOpen?.(false)}>
          Close
        </Button>
      </DialogContent>}
      {(account !== null && account !== undefined) && <DialogContent>
        <DialogHeader>
          <DialogTitle>Account Details</DialogTitle>
        </DialogHeader>
        <div className='flex justify-center mb-6 mt-4'>
          <div className='flex flex-col items-center'>
            <span className=''>Avalilable</span>
            <span className='font-semibold text-3xl'>{fmCurrency(account.balanceAvailable)}</span>
          </div>
        </div>
        <section className='mb-2'>
          <PrimaryItemDetail label='Account number' value={(account?.number ?? 0).toString()} />
          <PrimaryItemDetail
            label='Current balance'
            value={fmCurrency(account.amount)}
            className='text-teal-400'
          />
          <PrimaryItemDetail
            label='Total in transit'
            value={fmCurrency((account.amount) - (account.balanceAvailable))}
          />
          <PrimaryItemDetail label='Cut of date' value={'September 8th, 2023'} />
          <PrimaryItemDetail
            label='Payday limit'
            value={'September 25th, 2023'}
            divider={false}
          />
        </section>
        <Button className='w-full h-14 rounded-xl text-md dark:bg-primary dark:text-white dark:hover:bg-primary/90'>
          All statements
        </Button>
        <Button variant={'ghost'} className='h-14 rounded-lg'>
          DOWNLOAD
          {/* {false ? <Spinner className="animate-spin" /> : 'SAVE AS FAVORITE'} */}
        </Button>
      </DialogContent>}
    </Dialog>
  )
}

export { AccountDetails }
