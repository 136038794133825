import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { MessageCircleMoreIcon, MessageSquare, X } from 'lucide-react'
import { useState } from 'react'
import { Button } from '../ui/button'

const ChatFloatingButton = () => {
  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className='fixed bottom-4 right-4'>
        <button className='p-2 bg-primary rounded-full shadow-lg hover:scale-105' onClick={() => setOpen(!open)}>
          {
            open ? <X className='fill-white stroke-white' /> :
              <MessageSquare className='fill-white stroke-white' />
          }
        </button>
      </PopoverTrigger>
      <PopoverContent className='me-4 mb-2 w-[300px] h-[400px] shadow-none rounded-xl'>
        Chat with your friends
        <div className='flex flex-col gap-y-3 justify-center h-full items-center'>
          <MessageCircleMoreIcon size={50} />
          <h4 className='text-xs'>You dont have any chat</h4>
          <Button className='h-8  text-xs'>Start</Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export { ChatFloatingButton }
