import { ArrowRight } from '@/components/icons/ArrowRight'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { cn } from '@/lib/utils'

type Props = {
  title: string;
  description: string;
  icon: JSX.Element;
  variant?: 'default' | 'switch';
  size?: 'default' | 'lg'
  iconClassName: string;
  onClick?: () => void;
  isActive?: boolean;
  toggleActive?: (active: boolean) => void;
}

export default function MenuItem({ title, description, icon, variant = 'default', size = 'default', iconClassName, onClick, isActive, toggleActive }: Props) {
  const titleClass = cn(
    'text-base text-slate-700 dark:text-slate-300 font-semibold',
    {
      'text-sm': size !== 'lg'
    }
  )

  const arrowClass = cn(
    {
      'w-6 h-6': size === 'lg', // Apply larger size for lg
      'w-5 h-5': size !== 'lg' // Apply smaller size for non-lg
    },
    {
      'fill-slate-700 dark:fill-slate-300': variant !== 'switch', // Default color for non-switch variant
      'fill-primary': variant === 'switch' // Custom color for switch variant
    }
  )

  return (
    <Button disabled={!onClick} onClick={onClick} className={`hover:bg-gray-50 dark:hover:bg-[#100E24] dark:border dark:border-gray-900/30  cursor-pointer flex items-center justify-between px-4 gap-3 bg-white dark:bg-card shadow-sm rounded-xl ${size === 'lg' ? 'w-full h-full' : 'w-full h-16'}`}>
      <div className='flex items-center justify-start gap-3'>
        <div className={` dark:bg-[#5240f825] p-3 rounded-full dark:fill-[#c6d4db] ${iconClassName}`}>
          {icon}
        </div>
        <div className='text-start'>
          <h3 className={titleClass}>{title}</h3>
          <p className='text-xs text-slate-500'>{description}</p>
        </div>
      </div>
      {
        variant === 'switch' &&
        <Switch className='bg-primary' id='favorite' name='favorite' checked={isActive}
          onCheckedChange={toggleActive} aria-readonly
        />
      }
      {
        variant !== 'switch' && <div className='flex items-center'><ArrowRight className={arrowClass} /></div>
      }
    </Button>
  )
}
