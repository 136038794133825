
type Props = {
    className?: string
    size?: number
  }
const InfoIcon = (props: Props) => {
  const { size, className } = props
  return (
    <svg className={className} width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.448 60'>
      <g id='info' transform='translate(-336.776 -160)'>
        <path id='Path_1566' data-name='Path 1566' d='M357.764,160.271l-.919-.271h-2a27.951,27.951,0,0,0-2.975,1.082c-.27.162-.487.324-.757.487a6.652,6.652,0,0,0-1.515,9.25,7.45,7.45,0,0,0,7.465,3.029,7.036,7.036,0,0,0,5.734-4.327h-.054a3.788,3.788,0,0,0,.324-1.028A6.917,6.917,0,0,0,357.764,160.271Z'/>
        <path id='Path_1567' data-name='Path 1567' d='M361.821,213.715a11.535,11.535,0,0,1-5.734.7,2.727,2.727,0,0,1-2.6-2.434,9.491,9.491,0,0,1,.216-3.895c.649-2.542,1.353-5.085,2-7.681a54.162,54.162,0,0,0,2.6-11.738,7.547,7.547,0,0,0-4.057-7.249,12.72,12.72,0,0,0-8.114-.865,44.269,44.269,0,0,0-8.222,2.705.789.789,0,0,0-.325.27c-.27,1.082-.487,2.109-.811,3.245.7-.216,1.3-.432,1.839-.6a9.308,9.308,0,0,1,4.868-.324,2.647,2.647,0,0,1,2.11,2.163,10.144,10.144,0,0,1-.216,4.22q-1.542,6-3.246,12.009a28.54,28.54,0,0,0-1.3,7.627,7.112,7.112,0,0,0,4.273,6.978,13.754,13.754,0,0,0,9.521.541c2.326-.649,4.49-1.623,6.707-2.434a.653.653,0,0,0,.379-.379c.27-1.028.487-2,.757-3.029h-.216Z'/>
      </g>
    </svg>

  )
}
export { InfoIcon }
