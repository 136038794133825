import { WorkingDialog } from '@/components/common/WorkingDialog'
import { Shake } from '@/components/icons/Shake'
import { Spinner } from '@/components/icons/Spinner'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { getAccount, requestSavingAccount } from '@/services/products'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'sonner'
import InfoItem from '../../../common/InfoItem'
import CreatedAccount from './CreatedAccount'

type Props = {
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

const RequestAccount = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>()
  const [details, setDetails] = useState(false)
  const [terms, setTerms] = useState(false)
  const [id, setId] = useState<string>('')
  const { data: account } = useQuery({
    queryKey: ['account'],
    queryFn: () => getAccount(id),
    enabled: !!id
  })
  const requestAccountMutation = useMutation({
    mutationFn: () => requestSavingAccount(),
    onSuccess: (id) => {
      setOpen(false)
      queryClient.invalidateQueries({ queryKey: ['products'] })
      setId(id)
      setDetails(true)
      toast.success('Account created')
    }
  })
  const queryClient = useQueryClient()
  const handleTermsChange = (checked: boolean) => {
    setTerms(checked)
  }
  return (
    <div>
      <CreatedAccount open={details && !!account} setOpen={setDetails} account={account ?? null} />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {children}
        </DialogTrigger>
        <DialogContent className='sm:max-w-[425px] bg-[#F7F7FF]'>
          <DialogHeader className='flex flex-col items-center gap-4'>
            <DialogTitle>Saving Account</DialogTitle>
            <Shake className='fill-[#f4c573]/20 dark:fill-[#ffffff]/20 size-28' />
          </DialogHeader>
          <div className='flex flex-col gap-3 py-4 '>
            <div>
              <h2 className='font-semibold text-xl'>The new way to shake <span className='text-primary'>hands!</span></h2>
              <p className='text-sm text-slate-900 dark:text-slate-500'>We recomend you to read the whole contract; after all, knowledge is power.</p>
            </div>
            <h2 className='font-semibold text-xl'>Simple contract!</h2>
            <div className='flex flex-col gap-3 text-sm'>
              <InfoItem label='Requested product' value='Saving account' />
              <InfoItem label='Opening cost' value='RD$ 0.00' />
              <InfoItem label='Charges and commissions' value='RD$ 0.00' />
              <InfoItem label='Beneficios' value='A world of unlimited payments through QR, phone number and more' />
              <InfoItem label='Account statement' value='Access to your account statement 24/7' />
              <InfoItem label='Doubts or problems' value='Customer support (Bot, Chat, Email, Phone)' />
            </div>
            <WorkingDialog>
              <div className='text-primary text-center text-sm font-semibold mt-4'>View whole contract</div>
            </WorkingDialog>
          </div>
          <DialogFooter>
            <form onSubmit={(e) => e.preventDefault()} className='flex flex-col items-center gap-4'>
              <div className='flex items-center gap-4'>
                <Checkbox id='accept-terms' checked={terms} onCheckedChange={handleTermsChange} />
                <label htmlFor='accept-terms' className='text-xs'>By accepting, I confirm that I have carefully read and understood the terms and conditions of the contract.</label>
              </div>
              <Button className='w-20' disabled={requestAccountMutation.isPending || !terms} onClick={() => requestAccountMutation.mutate()}>
                {requestAccountMutation.isPending ? <Spinner className='h-4 w-4 animate-spin' /> : 'Accept'}
              </Button>
            </form>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RequestAccount