import { DialogTrigger } from '@/components/ui/dialog'

type props = {
  label: string;
  value?: string;
  divider?: boolean;
  classNames?: string;
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

const PrimaryItemDetail = ({
  label,
  value,
  divider = true,
  className = ''
}: props) => {
  return (
    <>
      <div className='flex justify-between'>
        <h3 className='font-semibold'>{label}</h3>
        <span className={`${className} font-thin text-gray-400 dark:text-gray-300`}>{value}</span>
      </div>
      {divider && (
        <div className='w-full border-t dark:border-slate-800 mt-4 pt-4'></div>
      )}
    </>
  )
}

export { PrimaryItemDetail }
