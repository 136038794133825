import { checkAndRefreshToken } from '@/services/auth/session'
import { ErrorResponse, HttpError, Params } from '@/types/https'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { navigate } from 'wouter/use-browser-location'

const _apiKey = 'b2cf292a08e8f9020b25c98a115b84a5'
const BASE_URL = 'https://admin.dev.lira.com.do/api'
const BASE_URL2 = 'https://core.dev.lira.com.do/api/app-front-end'
export const API_VERSION = 'v1'
// http://10.15.15.193:3000 freilyn
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-key': _apiKey
  }
})

const getToken = (): string | undefined => {
  return JSON.parse(localStorage.getItem('session') ?? '{}')?.state?.token
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {    
    if (error.response && error.response.status === 401) {
      const isLoginRoute = error.config?.url?.includes('/login')
      if (!isLoginRoute) {
        localStorage.removeItem('session')
        navigate('/login', { replace: true })
      }
    }
    return Promise.reject(error)
  }
)

const createAuthHeaders = async (djangoAPI: boolean) => {
  const token = djangoAPI ? await checkAndRefreshToken() : getToken()
  return { Authorization: `Bearer ${token}` }
}

export const http = {
  get: async <T>(url: string, { params, djangoAPI = false }: { params?: Params; djangoAPI?: boolean }): Promise<T | undefined> => {
    const headers = await createAuthHeaders(djangoAPI)
    try {
      const response: AxiosResponse<T> = await axiosInstance.get(`${djangoAPI ? BASE_URL2 : BASE_URL}${url}`, {
        params,
        headers
      })
      return response.data
    } catch (error) {
      return handleHttpError(error as AxiosError<ErrorResponse>)
    }
  },

  post: async <T>(url: string, { body = {}, djangoAPI = true }: { body?: Record<string, unknown>; djangoAPI?: boolean } = {}): Promise<T | undefined> => {
    const headers = await createAuthHeaders(djangoAPI)
    try {
      const response: AxiosResponse<T> = await axiosInstance.post(`${djangoAPI ? BASE_URL2 : BASE_URL}${url}`, body, { headers })
      return response.data
    } catch (error) {
      return handleHttpError(error as AxiosError<ErrorResponse>)
    }
  },

  put: async <T>(url: string, body: Record<string, unknown>, djangoAPI: boolean = true): Promise<T | undefined> => {
    const headers = await createAuthHeaders(djangoAPI)
    try {
      const response: AxiosResponse<T> = await axiosInstance.put(`${djangoAPI ? BASE_URL2 : BASE_URL}${url}`, body, { headers })
      return response.data
    } catch (error) {
      return handleHttpError(error as AxiosError<ErrorResponse>)
    }
  },

  delete: async <T>(url: string, djangoAPI: boolean = true): Promise<T | undefined> => {
    const headers = await createAuthHeaders(djangoAPI)
    try {
      const response: AxiosResponse<T> = await axiosInstance.delete(`${djangoAPI ? BASE_URL2 : BASE_URL}${url}`, { headers })
      return response.data
    } catch (error) {
      return handleHttpError(error as AxiosError<ErrorResponse>)
    }
  }
}

const handleHttpError = (error: AxiosError<ErrorResponse>): undefined => {
  const message = error.response?.data?.message || 'Unexpected error'
  const status = error.response?.status || 500
  throw new HttpError(message, status)
}
