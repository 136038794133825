type Props = {
  className?: string
}
const Shake = ({ className }: Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={ className } viewBox='0 0 108 108'>
    <g id='Group_1933' data-name='Group 1933' transform='translate(2003 11198)'>
      <g id='Group_1948' data-name='Group 1948' transform='translate(-3382.832 -11768.288)'>
        <g id='Group_1567' data-name='Group 1567' transform='translate(1379.832 570.288)'>
          <g id='Group_1618' data-name='Group 1618' transform='translate(0 0)'>
            <g id='Group_1527' data-name='Group 1527'>
              <g id='Group_1526' data-name='Group 1526'>
                <circle id='Ellipse_83' data-name='Ellipse 83' cx='54' cy='54' r='54' transform='translate(0 -0.001)' />
              </g>
            </g>
          </g>
        </g>
        <g id='Group_1619' data-name='Group 1619' transform='translate(1392.648 611.619)'>
          <path id='Path_1340' data-name='Path 1340' d='M1418.914,629.128a20.479,20.479,0,0,0,.534-3.159,5.154,5.154,0,0,0-3.958-5.076,1.277,1.277,0,0,1-1.074-1.068,5.176,5.176,0,0,0-4.016-3.847.922.922,0,0,1-.824-.759,5.373,5.373,0,0,0-4.073-4.04,1.291,1.291,0,0,1-.732-.71,5.465,5.465,0,0,0-9.585-1.578c-.884,1.136-1.757,2.28-2.673,3.473-1.074-.553-2.128-1.12-3.2-1.638-.569-.271-.377-.637-.241-1.044q1.87-5.6,3.736-11.2c.922-2.763,1.863-5.52,2.755-8.294.184-.575.431-.754,1.025-.651a9.279,9.279,0,0,0,5.675-.973c3.367-1.594,6.732-3.194,10.135-4.707,1.9-.843,3.213-.667,5.247.445-1.125.521-2.167,1.014-3.219,1.491-3.609,1.646-7.226,3.273-10.821,4.951a1.476,1.476,0,0,0-.778.949,18.136,18.136,0,0,0-.16,4.539c.437,2.63,2.741,4.376,5.615,4.661a12.308,12.308,0,0,0,6.255-.973c2.749-1.206,5.461-2.505,8.166-3.807a.81.81,0,0,1,1.09.144q8.862,8.284,17.756,16.534a2.725,2.725,0,0,1,.824,3.17,3.239,3.239,0,0,1-2.812,2.34,1.916,1.916,0,0,1-1.337-.464c-2.1-1.985-4.135-4.024-6.2-6.046-.149-.149-.277-.388-.453-.426a6.294,6.294,0,0,0-1.112-.081c.041.344-.041.792.146,1.017a24.473,24.473,0,0,0,1.771,1.795c1.545,1.518,3.069,3.058,4.65,4.536a1.822,1.822,0,0,1,.58,1.841c-.339,1.9-1.44,3.039-3.053,3.151a2.2,2.2,0,0,1-1.841-.708c-2.161-2.177-4.359-4.317-6.545-6.469-.193-.187-.377-.442-.613-.515s-.684-.073-.8.076a1.01,1.01,0,0,0-.081.87,3.056,3.056,0,0,0,.727.789c1.881,1.857,3.733,3.747,5.669,5.55a2.02,2.02,0,0,1,.629,2.318,2.345,2.345,0,0,1-1.942,1.79,3.914,3.914,0,0,1-3.649-1.047c-2.134-2.161-4.317-4.27-6.489-6.4-.371-.363-.781-.957-1.342-.388-.583.591-.008,1.019.4,1.418,2.036,2,4.053,4.026,6.125,5.992a1.191,1.191,0,0,1,.323,1.5,3.021,3.021,0,0,1-4.5,1.811A17.063,17.063,0,0,1,1418.914,629.128Z' transform='translate(-1377.089 -583.102)' fill='#e5a69a' />
          <path id='Path_1341' data-name='Path 1341' d='M1433.152,589.831l4.7,19.535c-1.776.439-3.449.878-5.141,1.239-.23.052-.585-.233-.811-.442q-8.691-8.008-17.35-16.051a1.207,1.207,0,0,0-1.629-.2q-4.62,2.245-9.3,4.365a9.248,9.248,0,0,1-4.856.708c-3.65-.388-4.569-3.1-3.9-6.049.049-.228.377-.455.627-.569,6.152-2.812,12.282-5.664,18.475-8.378a8.519,8.519,0,0,1,8.573.968,22.617,22.617,0,0,1,2.066,1.6A11.914,11.914,0,0,0,1433.152,589.831Z' transform='translate(-1367.27 -583.247)' fill='#f2b9b1' />
          <path id='Path_1342' data-name='Path 1342' d='M1393.645,609.347c-.2-.06-.4-.114-.6-.179-2.421-.805-4.843-1.6-7.258-2.421-1.247-.42-1.443-.789-1.041-2q3.3-9.952,6.62-19.9a1.2,1.2,0,0,1,1.749-.884q3.811,1.244,7.6,2.535c.913.312,1.144.808.829,1.754q-3.331,10.037-6.7,20.064c-.155.453-.651.789-.99,1.182Z' transform='translate(-1384.559 -582.213)' fill='#2e55bf' />
          <path id='Path_1343' data-name='Path 1343' d='M1415.466,608.372c-.838.016-1.136-.534-1.315-1.282q-2.045-8.585-4.124-17.163c-.155-.643-.323-1.282-.469-1.925a1.279,1.279,0,0,1,1.028-1.771q3.847-.947,7.708-1.838c.973-.222,1.458.1,1.7,1.106q2.33,9.627,4.645,19.256c.193.794.006,1.41-.827,1.621-2.706.681-5.423,1.323-8.134,1.979A1.4,1.4,0,0,1,1415.466,608.372Z' transform='translate(-1341.891 -581.398)' fill='#fe7078' />
          <path id='Path_1344' data-name='Path 1344' d='M1406.161,610.633c-2.007,1.09-3.316,1.1-4.526.041-1.4-1.226-1.475-2.722-.708-4.487-1.494.634-2.874.824-4.132-.211a3.533,3.533,0,0,1-1.068-3.923c-.307.06-.526.092-.743.146a3.347,3.347,0,0,1-3.579-1.312,3.4,3.4,0,0,1,.008-3.9c.705-.973,1.453-1.92,2.2-2.86a3.373,3.373,0,0,1,6.019,2.8c-.022.114-.035.23-.07.466a3.55,3.55,0,0,1,4.007.786c1.095,1.2,1.133,2.589.417,4.211,1.7-.775,3.219-.784,4.46.545,1.223,1.312,1.122,2.806.3,4.371,2.164-.873,3.476-.708,4.615.526a3.37,3.37,0,0,1,.222,4.24c-.5.675-1.022,1.337-1.545,2a3.367,3.367,0,0,1-5.954-2.977C1406.112,610.983,1406.123,610.869,1406.161,610.633Z' transform='translate(-1373.885 -567.067)' fill='#f2b9b1' />
        </g>
      </g>
      <g id='Group_1949' data-name='Group 1949' transform='translate(-2134.352 -11288.59)'>
        <path id='Path_1345' data-name='Path 1345' d='M-5749.442-12060.79l-10.206-9.278' transform='translate(5922 12186.444)' fill='none' stroke='#f4c573' strokeLinecap='round' strokeWidth='3.5' />
        <path id='Path_1347' data-name='Path 1347' d='M-5759.648-12060.79l10.206-9.278' transform='translate(5956 12186.444)' fill='none' stroke='#f4c573' strokeLinecap='round' strokeWidth='3.5' />
      </g>
      <path id='Path_1547' data-name='Path 1547' d='M-5736.849-12065.429v-15.112' transform='translate(3787.349 900.771)' fill='none' stroke='#f4c573' strokeLinecap='round' strokeWidth='3.5' />
    </g>
  </svg>
)

export { Shake }
