import { z } from 'zod'

export enum ProductStatus {
    ACTIVE = 'active',
    BLOCK = 'block',
    BLOCKED = 'blocked',
    CANCELLED = 'cancelled',
}

const CurrencySchema = z.object({
  symbol: z.string(),
  isoCode: z.string(),
  name: z.string()
})


// `${card.cutoffDate as number} ${(new Date()).toLocaleDateString('default', { month: 'long' })}`
export const CardSchema = z.object({
  id: z.string(),
  userId: z.string(),
  number: z.number(),
  amount: z.number(),
  balanceToCutoff: z.number().default(0),
  balanceToDate: z.number(),
  cutoffDate: z.number().transform((cutoffDate) => {
    const monthName = new Date().toLocaleDateString('default', { month: 'long' })
    return `${cutoffDate} ${monthName}`
  }),
  approvedLimit: z.number(),
  balanceAvailable: z.number(),
  holder: z.string().default(''), //TODO: No esta llegando este valor
  paydayLimit: z.date().optional(), //TODO: No esta llegando
  minimumPayment: z.number(),
  dateOfExpiry: z.date().optional(), //TODO: No esta llegando
  type: z.number(),
  productType: z.literal('Credit card').default('Credit card'),
  typeLocation: z.literal('cards').default('cards'),
  colorId: z.number(),
  status: z.nativeEnum(ProductStatus),
  createdAt: z.string().transform((str) => new Date(str)),
  updatedAt: z.string().transform((str) => new Date(str))
})
  
export type Card = z.infer<typeof CardSchema>;

export const AccountSchema = z.object({
  iban: z.string(),
  updatedAt: z.string().transform((str) => new Date(str)),
  balanceReserved: z.number(),
  isDefault: z.boolean(),
  currentQRCodeIdCreation: z.string().transform((str) => new Date(str)),
  userId: z.string().uuid(),
  currency: CurrencySchema,
  status: z.nativeEnum(ProductStatus),
  productType: z.literal('Saving account').default('Saving account'),
  typeLocation: z.literal('accounts').default('accounts'),
  colorId: z.number(),
  id: z.string(),
  createdAt: z.string().transform((str) => new Date(str)),
  amount: z.number(),
  balanceAvailable: z.number(),
  currentQRCodeId: z.string().uuid(),
  type: z.string(),
  typeId: z.number(),
  number: z.number()
})
  
export type Account = z.infer<typeof AccountSchema>;
  
export const MinimalisticAccountSchema = z.object({
  number: z.number(),
  designType: z.number(),
  colorId: z.number(),
  typeId: z.number(),
  id: z.string().uuid(),
  type: z.string(),
  userId: z.string().uuid()
})

export type MinimalisticAccount = z.infer<typeof MinimalisticAccountSchema>;

export const DepositCertificateSchema = z.object({
  id: z.string(),
  paymentInterestType: z.string(),
  interestRate: z.number(),
  amount: z.number(),
  endDate: z.string(),
  accumulatedInterest: z.number(),
  currentAmount: z.number(),
  userId: z.string(),
  duration: z.number(),
  shortNumber: z.string(),
  number: z.string(),
  balanceAvailable: z.number(),
  accountToCredit: MinimalisticAccountSchema.nullish(),
  statusId: z.number(),
  startDate: z.string(),
  dayOfPayment: z.number(),
  nextPaymentDate: z.string().transform((str) => new Date(str)),
  status: z.nativeEnum(ProductStatus),
  monthlyInterestAmount: z.number(),
  productType: z.literal('Certificate of Deposit').default('Certificate of Deposit'),
  typeLocation: z.literal('certificates').default('certificates'),
  createdAt: z.string().transform((str) => new Date(str)),
  updatedAt: z.string().transform((str) => new Date(str))
})
  
export type DepositCertificate = z.infer<typeof DepositCertificateSchema>;

export const AccountTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  activated: z.boolean(),
  createdAt: z.string().transform((str) => new Date(str)),
  updatedAt: z.string().transform((str) => new Date(str))
})
  
export type AccountType = z.infer<typeof AccountTypeSchema>;

export const CertificateApplicationSchema = z.object({
  id: z.number(),
  status: z.string(),
  amount: z.string(),
  term: z.string(),
  rate: z.string(),
  amountToBeGeneratedMonthly: z.string(),
  ratePayment: z.string(),
  cancellationPenalty: z.string(),
  interestPaymentDate: z.string(),
  automaticRenewal: z.string(),
  accountToBeDebited: z.string()
})
  
export type CertificateApplication = z.infer<typeof CertificateApplicationSchema>;

export const CancelationPenaltyCertificateSchema = z.object({
  interestGainedNotPaid: z.number(),
  currentAmount: z.number(),
  totalToDeposit: z.number(),
  penaltyAmount: z.number(),
  percentage: z.number(),
  balance: z.number()
})

export type CancelationPenaltyCertificate = z.infer<typeof CancelationPenaltyCertificateSchema>;

export const ProductResponseSchema = z.object({
  accounts: z.array(AccountSchema),
  cards: z.array(CardSchema),
  certificates: z.array(DepositCertificateSchema)
})
  
export type ProductResponse = z.infer<typeof ProductResponseSchema>;

export const CreditCardContratResponseSchema = z.object({
  applyId: z.number(),
  productName: z.string(),
  openingCost: z.number(),
  annualCost: z.number(),
  benefits: z.array(z.string()),
  billingDay: z.string(),
  questionsOrIssues: z.array(z.string()),
  contractUrl: z.string(),
  minimumAmount: z.number(),
  approvedLimit: z.number()
})
  
export type CreditCardContrat = z.infer<typeof CreditCardContratResponseSchema>;

export const RequestCreditCardResponseSchema = z.object({
  applyId: z.number(),
  contract: CreditCardContratResponseSchema,
  certificates: z.array(DepositCertificateSchema)
})
  
export type RequestCreditCardResponse = z.infer<typeof RequestCreditCardResponseSchema>;

export const ProductSchema = z.union([AccountSchema, CardSchema, DepositCertificateSchema])

export type Product = z.infer<typeof ProductSchema>;
