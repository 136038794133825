import { Button } from '@/components/ui/button'
import { Bell } from 'lucide-react'

export function Notification() {

  return (
    <Button
      variant='ghost'
      size='icon'
      className='h-9 w-9 rounded-full'
    >
      <Bell className='h-4 w-4 text-primary fill-primary' />
    </Button>
  )
}