import { CardsImg } from '@/components/icons/CardsImg'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Slider } from '@/components/ui/slider'
import { fmCurrency } from '@/lib/constants'
import { useState } from 'react'

type Props = {
  onApply: ({ amount }: { amount: number }) => void
}
export const DesiredLimitCreditCard = ({ onApply }: Props) => {
  const [value, setValue] = useState([5000])
  const [terms, setTerms] = useState(false)

  const minAmount = 5000
  const maxAmount = 800000

  const handleTermsChange = (checked: boolean) => {
    setTerms(checked)
  }

  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle className='text-slate-800 dark:text-slate-200'>
          Request your <span className='text-primary'>credit card</span>
        </DialogTitle>
        <CardsImg />
      </DialogHeader>
      <div className='flex flex-col gap-3 py-4'>
        <div className='flex gap-3'>
          <span className='text-sm text-slate-900 dark:text-slate-500'>{fmCurrency(minAmount)}</span>
          <Slider
            className='text-primary'
            defaultValue={value}
            max={maxAmount}
            step={1000}
            min={minAmount}
            onValueChange={setValue}
          />
          <span className='text-sm text-slate-900 dark:text-slate-500'>{fmCurrency(maxAmount)}</span>
        </div>
        <div className='grid justify-center'>
          <h2 className='text-sm text-slate-900 dark:text-slate-500'>Slide to set a limit</h2>
          <span className='font-semibold text-xl text-primary'>{fmCurrency(value[0])}</span>
        </div>
      </div>
      <DialogFooter>
        <form onSubmit={(e) => e.preventDefault()} className='flex flex-col items-center gap-4 w-full'>
          <div className='flex items-start justify-start gap-4'>
            <Checkbox id='accept-terms' checked={terms} onCheckedChange={handleTermsChange} />
            <label htmlFor='accept-terms' className='text-xs'>
              By accepting, I authorize Lira to request my credit record.
            </label>
          </div>
          <Button disabled={!terms} onClick={() => onApply({ amount: value[0] })}>Continue</Button>
        </form>
      </DialogFooter>
    </>
  )
}