import {
  Avatar,
  AvatarFallback,
  AvatarImage
} from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useSession } from '@/hooks/useSession'
import { auth } from '@/services/firebaseConfig'
import { ChevronDown, LogOut } from 'lucide-react'
import { useLocation } from 'wouter'

export function UserNav() {
  const [, setLocation] = useLocation()
  const { reset, user } = useSession()
  const logout = async () => {
    localStorage.removeItem('token')
    await auth.signOut()
    reset()
    setLocation('/login')
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant='ghost' className='relative gap-2 px-2'>
          <Avatar className='size-8 border dark:border-dark-border'>
            <AvatarImage src='/avatars/03.png' alt='@shadcn' />
            <AvatarFallback className='bg-transparent dark:bg-card'>A</AvatarFallback>
          </Avatar>
          <span className='text-slate-700 dark:text-slate-300 text-sm hidden sm:flex'>{`${user?.name} ${user?.lastName}`}</span>
          <ChevronDown className='w-4 h-4 text-gray-500' />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-56 dark:bg-card dark:border-dark-border' align='end' forceMount>
        <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <p className='text-sm font-medium leading-none'>{user?.name} {user?.lastName}</p>
            <p className='text-xs leading-none text-muted-foreground'>
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className='cursor-pointer'>
            Profile
          </DropdownMenuItem>
          <DropdownMenuItem className='cursor-pointer'>
            Settings
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout} className='text-red-600/90 dark:text-red-400 flex cursor-pointer gap-2'>
          Log out <LogOut className='size-4' />
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}