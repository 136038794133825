export interface Params {
    [key: string]: string | number | boolean | undefined;
  }
  
export interface ErrorResponse {
    message: string;
    status: number;
  }
  
export class HttpError extends Error {
  status: number
  constructor(message: string, status: number) {
    super(message)
    this.status = status
    Object.setPrototypeOf(this, HttpError.prototype)
  }
}
  