type Props = {
  className?: string
  size?: number
}
export const SavingsIcon = (props: Props) => {
  const { size, className } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className={className} width={size} height={size} viewBox='0 0 60 48.68'>
      <path id='savings' d='M379.945,29.577a6.679,6.679,0,0,0-4.674-3.31,3.683,3.683,0,0,0-3.387,1.127,5.141,5.141,0,0,0-.413.508,12.792,12.792,0,0,0-1.918.032v-.043C368.3,18.4,358.67,11.014,346.961,11.014a26.635,26.635,0,0,0-8.391,1.341h0a10.461,10.461,0,0,0-6.745-6.695V15.838h0A18.886,18.886,0,0,0,325.1,24.8h-3.88A1.217,1.217,0,0,0,320,26.013v8.982a1.217,1.217,0,0,0,1.217,1.218h4.269A19.746,19.746,0,0,0,333.7,45.4v7.728a1.217,1.217,0,0,0,1.217,1.217h8.645a1.217,1.217,0,0,0,1.217-1.217V48.872c.715.056,1.44.087,2.174.087.862,0,1.714-.043,2.551-.121v4.285a1.217,1.217,0,0,0,1.217,1.217h8.645a1.217,1.217,0,0,0,1.217-1.217V45.167a18.12,18.12,0,0,0,9.1-15.181c0-.292-.011-.584-.025-.87v-.094a12.5,12.5,0,0,1,1.266-.046,3.158,3.158,0,0,0,.111,2.368,2.242,2.242,0,0,0,2.006,1.342h.166a2.144,2.144,0,0,0,1.947-1.57,2.305,2.305,0,0,0-1.157-2.51,3.953,3.953,0,0,0-1.252-.514,2.608,2.608,0,0,1,2.376-.737,5.646,5.646,0,0,1,3.85,2.7.546.546,0,1,0,.98-.48Zm-23.657-12.8a.912.912,0,0,1-1.209.454,16.533,16.533,0,0,0-10.99-.884.915.915,0,0,1-1.153-.583h0a.916.916,0,0,1,.584-1.153,18.192,18.192,0,0,1,12.316.958A.913.913,0,0,1,356.288,16.774Zm17.1,12.751a1.251,1.251,0,0,1,.7,1.32,1.043,1.043,0,0,1-.974.75,1.145,1.145,0,0,1-1.1-.721,2.132,2.132,0,0,1,.043-1.8,3.4,3.4,0,0,1,1.334.45Z' transform='translate(-320 -5.66)' />
    </svg>

  )
}
