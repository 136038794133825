import { lazy } from 'react'

const Beneficiaries = lazy(() => import('@/pages/Beneficiaries'))
const Products = lazy(() => import('@/pages/products/Products'))
const Dashboard = lazy(() => import('@/pages/Dashboard'))
// const Help = lazy(() => import('@/pages/Help'))
// const Simulators = lazy(() => import('@/pages/Simulators'))
const Splits = lazy(() => import('@/pages/Splits'))
const AccountView = lazy(() => import('@/pages/products/AccountView'))
const CreditCardView = lazy(() => import('@/pages/products/CreditCardView'))
const TerminalView = lazy(() => import('@/pages/terminals/TerminalView'))
const Terminals = lazy(() => import('@/pages/terminals/Terminals'))
const Transfer = lazy(() => import('@/pages/Transfer'))
const WorkingPage = lazy(() => import('@/pages/Working'))
const LockPage = lazy(() => import('@/pages/LockPage').then(m => ({ default: m.LockPage })))
const Login = lazy(() => import('@/pages/Login').then(m => ({ default: m.Login })))
const Payments = lazy(() => import('@/pages/payments/Payments'))
const Massive = lazy(() => import('@/pages/payments/Massive'))
const Services = lazy(() => import('@/pages/payments/Services'))
const RequestProducts = lazy(() => import('@/pages/RequestProducts'))
const Receive = lazy(() => import('@/pages/Receive'))
const CertificateView = lazy(() => import('@/pages/products/CertificateView'))
const NotFound = lazy(() => import('@/pages/NotFound'))

import { WorkingDialog } from '@/components/common/WorkingDialog'
import { CreditCardIcon } from '@/components/icons/CreditCardIcon'
import { SavingsIcon } from '@/components/icons/SavingsIcon'
import RequestAccount from '@/components/products/account/request/RequestAccount'
import { RequestCreditCard } from '@/components/products/credit-card/request/RequestCreditCard'
import { RequestDepositCertificate } from '@/components/products/credit-card/request/RequestDepositCertificate'
import { NavItem } from '@/types'
import { ArrowDownUp, BookOpenCheck, Calculator, CreditCard, Gift, Headset, LayoutDashboard, MoveDown, MoveUp, PiggyBank, Puzzle, Settings, Store, UserRound, Wallet } from 'lucide-react'

export const NAV_ITEMS: NavItem[] = [
  {
    title: 'Dashboard',
    icon: LayoutDashboard,
    href: '/',
    component: Dashboard
  },
  {
    title: 'My products',
    icon: Wallet,
    href: '/products',
    component: Products
  },
  {
    href: '/products/accounts/:id',
    component: AccountView
  },
  {
    href: '/products/cards/:id',
    component: CreditCardView
  },
  {
    href: '/products/certificates/:id',
    component: CertificateView
  },
  {
    title: 'Transfer',
    icon: ArrowDownUp,
    href: '/transfers',
    component: Transfer
  },
  {
    title: 'Pay',
    icon: MoveUp,
    href: '/pays',
    component: Payments
  },
  {
    href: '/pays/massive',
    component: Massive
  },
  {
    href: '/pays/services',
    component: Services
  },
  {
    title: 'Receive',
    icon: MoveDown,
    href: '/receive',
    component: Receive
  },
  // {
  //   title: 'Contacts',
  //   icon: Contact2,
  //   href: '/contacts',
  //   component: NotFound
  // },
  {
    title: 'Beneficiaries',
    icon: UserRound,
    href: '/beneficiaries',
    component: Beneficiaries
  },
  {
    title: 'Request products',
    icon: CreditCard,
    href: '/request',
    component: RequestProducts
  },
  {
    title: 'Terminal',
    icon: Store,
    href: '/terminals',
    component: Terminals
  },
  {
    href: '/terminals/:id',
    component: TerminalView
  },
  {
    title: 'Goals',
    icon: PiggyBank,
    href: '/goals',
    component: WorkingPage
  },
  {
    title: 'Splits',
    icon: Puzzle,
    href: '/splits',
    component: Splits
  },
  {
    title: 'Simulators',
    icon: Calculator,
    href: '/simulators',
    component: WorkingPage
  },
  {
    title: 'Offers',
    icon: Gift,
    href: '/offers',
    component: WorkingPage
  },
  {
    title: 'Help and contact',
    icon: Headset,
    href: '/help',
    component: WorkingPage
  },
  {
    title: 'Settings',
    icon: Settings,
    href: '/settings',
    component: WorkingPage
  },
  {
    title: 'Lock',
    href: '/lock',
    component: LockPage,
    auth: false
  },
  {
    title: 'Login',
    href: '/login',
    component: Login,
    auth: false
  },
  {
    href: '',
    auth: false,
    component: NotFound
  }
]
export const UNAUTH_ROUTES = NAV_ITEMS.filter(({ auth }) => auth === false)
export const AUTH_ROUTES = NAV_ITEMS.filter(({ auth }) => auth === undefined)

export const fmCurrency = (value: number) => {
  return new Intl.NumberFormat('es-DO', { style: 'currency', currency: 'DOP' }).format(value)
}
export const fmDate = (value: string) => {
  return new Intl.DateTimeFormat('es-DO', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(value))
}
interface Bank {
  id: number;
  description: string;
  logo: string;
  nickname: string;
  color: string; // Suponiendo que el color se represente como una cadena en formato hexadecimal
  code: string;
}
const BANKS: Bank[] = [
  {
    id: 1,
    description: 'Banco López de Haro, S. A.',
    logo: 'assets/images/banks/lopez-de-haro-logo.jpeg',
    nickname: 'López de Haro',
    color: '#2e4b7f',
    code: 'BLDHDOSD'
  },
  {
    id: 2,
    description: 'Banco Santa Cruz, S. A.',
    logo: 'assets/images/banks/santa-cruz-logo.jpeg',
    nickname: 'Santa Cruz',
    color: '#06a857',
    code: 'BSCRDOSD'
  },
  {
    id: 3,
    description: 'Banco BDI, S. A.',
    logo: 'assets/images/banks/bdi-logo.png',
    nickname: 'BDI',
    color: '#7f7f7f',
    code: 'BDIRDOSD'
  },
  {
    id: 4,
    description: 'Banco Ademi, S. A.',
    logo: 'assets/images/banks/ademi-logo.jpg',
    nickname: 'Ademi',
    color: '#0699ac',
    code: 'ADMRDOSD'
  },
  {
    id: 5,
    description: 'Banco Vimenca, S. A.',
    logo: 'assets/images/banks/logo-vimenca.jpg',
    nickname: 'Vimenca',
    color: '#0e4d94',
    code: 'BVIMRDOSD'
  },
  {
    id: 6,
    description: 'Banco BHD León, S. A.',
    logo: 'assets/images/banks/bhd-logo.jpeg',
    nickname: 'BHD León',
    color: '#57bb43',
    code: 'BHDLRDOSD'
  },
  {
    id: 7,
    description: 'Banco Caribe Internacional, S. A.',
    logo: 'assets/images/banks/caribe-logo.png',
    nickname: 'Caribe',
    color: '#066253',
    code: 'CARBRDOSD'
  },
  {
    id: 8,
    description: 'Scotiabank República Dominicana, S. A.',
    logo: 'assets/images/banks/scotiabank-logo.png',
    nickname: 'Scotiabank',
    color: '#ed252d',
    code: 'NOSJRDDR'
  },
  {
    id: 9,
    description: 'Banco de Reservas de la República Dominicana',
    logo: 'assets/images/banks/reservas-logo.jpeg',
    nickname: 'Reservas',
    color: '#405a6b',
    code: 'BNRDRDSD'
  },
  {
    id: 10,
    description: 'Banco Activo Dominicana, S. A.',
    logo: 'assets/images/banks/banco-activo-logo.png',
    nickname: 'Activo',
    color: '#064c84',
    code: 'BATCRDDD'
  },
  {
    id: 11,
    description: 'Banco Lafise, S. A.',
    logo: 'assets/images/banks/lafise-logo.png',
    nickname: 'Lafise',
    color: '#066041',
    code: 'BLAFDRDS'
  },
  {
    id: 12,
    description: 'Citibank, N. A.',
    logo: 'assets/images/banks/citibank-logo.jpeg',
    nickname: 'Citibank',
    color: '#064b88',
    code: 'CITIDOSD'
  },
  {
    id: 13,
    description: 'Banesco Banco  S. A.',
    logo: 'assets/images/banks/banesco-logo.png',
    nickname: 'Banesco',
    color: '#e22d25',
    code: 'BNESDOSD'
  },
  {
    id: 14,
    description: 'Banco Popular Dominicano, S. A.',
    logo: 'assets/images/banks/popular-logo.jpg',
    nickname: 'Popular',
    color: '#25376d',
    code: 'BPORDOSD'
  },
  {
    id: 15,
    description: 'Banco Promerica de la República Dominicana, S. A.',
    logo: 'assets/images/banks/banco-promerica-logo.jpeg',
    nickname: 'Promerica',
    color: '#196f3e',
    code: 'PRORDOSD'
  },
  {
    id: 16,
    description: 'Banco Bellbank, S. A.',
    logo: 'assets/images/banks/bell-bank-logo.png',
    nickname: 'Bellbank',
    color: '#068c98',
    code: 'BLBKRDSD'
  },
  {
    id: 17,
    description: 'Banco de Las Americas, S. A. (Bancamerica)',
    logo: 'assets/images/banks/bancamerica-logo.png',
    nickname: 'Bancamerica',
    color: '#d33d37',
    code: 'BNCRDRDD'
  },
  {
    id: 18,
    description: 'Qik Banco Digital',
    logo: 'assets/images/banks/qik.jpeg',
    nickname: 'Qik',
    color: '#4ab9ff',
    code: 'QIKDRDDD'
  }
]

export const productsToRequest = [
  {
    title: 'Card',
    description: 'Withdraw money, buy online and much more.',
    icon: CreditCardIcon,
    action: RequestCreditCard,
    iconClassName: 'bg-blue-600/20 fill-blue-600 dark:bg-[#93D4FC]/80 dark:fill-[#ffffff]'
  },
  {
    title: 'Savings account',
    description: 'Saving money have never been better.',
    icon: SavingsIcon,
    action: RequestAccount,
    iconClassName: 'bg-[#443DC4]/20 fill-[#443DC4] dark:bg-[#443DC4]/80 dark:fill-[#ffffff]'
  },
  {
    title: 'Loans',
    description: 'Loans for your needs.',
    icon: BookOpenCheck,
    action: WorkingDialog,
    iconClassName: 'bg-[#F4C573]/20 fill-[#F4C573] dark:bg-[#F4C573]/80 dark:fill-[#ffffff]'
  },
  {
    title: 'Certificates of deposit',
    description: 'Request a certificate of deposit here.',
    icon: BookOpenCheck,
    action: RequestDepositCertificate,
    iconClassName: 'bg-blue-600/20 text-blue-600 dark:bg-[#93D4FC]/80 dark:fill-[#ffffff]'
  }
]

export const shareOptions = [
  {
    title: 'Whatsapp',
    description: 'Share on whatsapp',
    icon: CreditCardIcon,
    action: WorkingDialog,
    iconClassName: 'bg-blue-600/20 fill-blue-600 dark:bg-[#93D4FC]/80 dark:fill-[#ffffff]'
  }
]
export const findBankByCode = (code?: string) => {
  return BANKS.find((bank) => bank.code === code)
}

const gradients: string[][] = [
  ['#F4C573', '#FE7078'], // warning, danger
  ['#ECA8DD', '#FE7078'], // Color(0xFFECA8DD), danger
  ['#43D6DD', '#C72DC5'], // blue300, plusBerry
  ['#8EE6EB', '#F4B3EC'], // Color(0xFF8EE6EB), Color(0xFFF4B3EC)
  ['#93D4FC', '#D7DF21'], // blue200, Color(0xFFD7DF21)
  ['#8F9FEA', '#43D6DD'], // primary2, blue300
  ['#B429F9', '#26C5F3'], // Color(0xFFB429F9), Color(0xFF26C5F3)
  ['#BA3429', '#9243A9'], // Color(0xFFBA3429), Color(0xFF9243A9)
  ['#D7EBEB', '#F4AFE9'], // Color(0xFFD7EBEB), Color(0xFFF4AFE9)
  ['#6EF195', '#00E3FD'], // Color(0xFF6EF195), Color(0xFF00E3FD)
  ['#FF0F7B', '#F89B29'], // Color(0xFFFF0F7B), Color(0xFFF89B29)
  ['#F44369', '#3E3B92'], // Color(0xFFF44369), Color(0xFF3E3B92)
  ['#57EBDE', '#AEFB2A'], // Color(0xFF57EBDE), Color(0xFFAEFB2A)
  ['#83F5E5', '#E761BD'], // Color(0xFF83F5E5), Color(0xFFE761BD)
  ['#C11E38', '#220B34'], // Color(0xFFC11E38), Color(0xFF220B34)
  ['#F2C2EE', '#E763F9'] // Color(0xFFF2C2EE), Color(0xFFE763F9)
]

export const applyGradient = (index?: number) => {
  if (index === null || index === undefined) return {}

  if (index > gradients.length - 1 || index === undefined) {
    index = 1
  }
  const styles = {
    background: `linear-gradient(90deg, ${gradients[index][0]}, ${gradients[index][1]})`
  }
  return styles
}

export type DialogContent = {
  title: string
  content: string
  event: () => void
}