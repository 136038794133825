import { CardsImg } from '@/components/icons/CardsImg'
import { Button } from '@/components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Slider } from '@/components/ui/slider'
import { fmCurrency } from '@/lib/constants'
import { useEffect, useState } from 'react'
type Props = {
  minApprovedLimit: number
  approvedLimit: number
  onSelectLimitAmount: (amount: number) => void
}
export const ApprovedCreditCard = ({ minApprovedLimit, approvedLimit, onSelectLimitAmount }: Props) => {
  const [value, setValue] = useState<number[]>([approvedLimit])
  useEffect(() => {
    setValue([approvedLimit])
  }, [approvedLimit])

  return (
    <>
      {/* <CreditCardContract open={openContract} setOpen={setOpenContract} onSuccess={handleRequest} /> */}
      <div>
        <DialogHeader className='flex flex-col items-center gap-4'>
          <DialogTitle className='text-slate-800 dark:text-slate-200'>
            Congratulations! Approved limit <span className='text-primary'>{fmCurrency(approvedLimit)}</span>
          </DialogTitle>
          <CardsImg />
        </DialogHeader>
        <div className='flex flex-col gap-3 py-4 '>
          <div className='flex gap-3'>
            <span className='text-sm text-slate-900 dark:text-slate-500'>{fmCurrency(minApprovedLimit)}</span>
            <Slider
              className='text-primary'
              defaultValue={value}
              max={approvedLimit}
              step={1000}
              min={minApprovedLimit}
              onValueChange={setValue}
            />
            <span className='text-sm text-slate-900 dark:text-slate-500'>{fmCurrency(approvedLimit)}</span>
          </div>
          <div className='grid justify-center'>
            <h2 className='text-sm text-slate-900 dark:text-slate-500'>Slide to set a limit</h2>
            <span className='font-semibold text-xl text-primary'>{fmCurrency(value[0])}</span>
          </div>
        </div>
      </div>
      <DialogFooter>
        <div className='flex justify-center items-center w-full'>
          <Button onClick={() => onSelectLimitAmount(value[0])}>Continue</Button>
        </div>
      </DialogFooter>
    </>
  )
}