type Props = {
  className?: string
  size?: number
}
export const CreditCardIcon = (props: Props) => {
  const { size, className } = props
  return (
    <svg className={className} width={size} height={size} viewBox='0 0 60 37.498'>
      <g id='credit_card' data-name='credit card' transform='translate(-240 -11.251)'>
        <path id='Exclusion_2' data-name='Exclusion 2' d='M295.368,48.749H244.632A4.637,4.637,0,0,1,240,44.117V15.883a4.637,4.637,0,0,1,4.632-4.632h50.736A4.637,4.637,0,0,1,300,15.883V44.117A4.637,4.637,0,0,1,295.368,48.749Zm-25.368-9a1.5,1.5,0,0,0,0,3h22.5a1.5,1.5,0,0,0,0-3Zm10.5-4.5a1.5,1.5,0,0,0,0,3h12a1.5,1.5,0,0,0,0-3Zm-31.932-10.8a1.276,1.276,0,0,0-1.275,1.274v3.961a1.278,1.278,0,0,0,1.275,1.275h5.217a1.276,1.276,0,0,0,1.274-1.275V25.723a1.275,1.275,0,0,0-1.274-1.274Z' />
      </g>
    </svg>
  )
}
