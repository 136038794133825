import { ChatFloatingButton } from '../chat/ChatFloatingButton'
import Header from './Header'
import Sidebar from './Sidebar'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <div className='flex h-screen border-collapse overflow-hidden'>
        <Sidebar />
        <main className='flex-1 overflow-y-auto overflow-x-hidden pt-24 bg-[#F7F7FF] dark:bg-primary-dark pb-1 px-10'>
          {children}
        </main>
        <ChatFloatingButton />
      </div>
    </>
  )
}