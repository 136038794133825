import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/components/ui/command'
import { AUTH_ROUTES } from '@/lib/constants'
import { cn } from '@/lib/utils'
import { LucideIcon, Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { Button } from '../ui/button'

export function GlobalSearch() {
  const [open, setOpen] = useState(false)
  const [location, setLocation] = useLocation()

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (
    <>
      <Button variant='ghost' size={'icon'} className='h-9 w-9 rounded-full' onClick={() => setOpen(true)}>
        <Search className='h-4 w-4 text-gray-400' />
        {/* <kbd className='pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100 dark:border-slate-700 dark:bg-slate-800'>
          <span className='text-xs'>⌘</span>K
        </kbd> */}
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder='Type a command or search...' />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {/* <CommandGroup heading="Suggestions">
            <CommandItem>Calendar</CommandItem>
            <CommandItem>Search Emoji</CommandItem>
            <CommandItem>Calculator</CommandItem>
          </CommandGroup> */}
          <CommandSeparator />
          <CommandGroup heading='Pages'>
            {
              AUTH_ROUTES.filter((item) => item.title !== undefined && item.icon !== undefined).map((item) => {
                const { title, icon, href, color, children } = item
                const Icon: LucideIcon = icon!
                const childrenToRender = children?.filter((item) => item.title !== undefined && item.icon !== undefined)
                return childrenToRender && childrenToRender?.length > 0 ?
                  (
                    <CommandGroup key={href} heading={title}>
                      {childrenToRender?.map((child) => {
                        const Icon: LucideIcon = child.icon!
                        return (
                          <CommandItem className={`cursor-pointer rounded-lg flex gap-2 ${location === child.href && 'cursor-not-allowed'}`} onSelect={() => setLocation(child.href)} key={child.href}>
                            <Icon className={cn('h-5 w-5 text-csecondary', color)} />
                            {child.title}
                          </CommandItem>
                        )
                      })}
                    </CommandGroup>
                  ) :
                  <CommandItem key={href} className={`cursor-pointer rounded-lg flex gap-2 ${location === href && 'cursor-not-allowed'}`} onSelect={() => setLocation(href)}>
                    <Icon className={cn('h-5 w-5 text-csecondary', color)} />
                    {title}
                  </CommandItem>
              })
            }
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  )
}
