import { fmCurrency } from '@/lib/constants'
import { http } from '@/lib/http'
import { Account, AccountSchema, AccountType, AccountTypeSchema, CancelationPenaltyCertificateSchema, Card, CardSchema, CertificateApplication, CertificateApplicationSchema, CreditCardContrat, CreditCardContratResponseSchema, DepositCertificate, DepositCertificateSchema, ProductResponseSchema } from '@/types/product'
import { toast } from 'sonner'

const getProducts = async () => {
  const response = await http.get<DataRecord>('/products', {})
  const certificates = response.certificates.map((certificate: DataRecord) => {
    return jsonToCertificate(certificate)
  })
  response.certificates = certificates


  const accounts = response.accounts.map((account: DataRecord) => {
    const currency = jsonToCurrency(account.currency)
    const updatedAccount = { ...account, currency }
    return updatedAccount
  })
  response.accounts = accounts

  const result = ProductResponseSchema.safeParse(response)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const requestCreditCardApply = async ({ amount }: { amount: number }) => {
  const { data } = await http.post<DataRecord>('/card/apply', { body: { details_field: { desired_amount: amount }, auto_create_product: true  } })
  const { id, results_field } = data
  const contractData = results_field.details
  const contract: CreditCardContrat = {
    applyId: id,
    productName: contractData.requested_product,
    openingCost: contractData.opening_cost,
    annualCost: contractData.annual_cost,
    benefits: contractData.benefits,
    billingDay: contractData.billing_day,
    questionsOrIssues: contractData.questions_or_issues,
    contractUrl: contractData.contract_url,
    minimumAmount: results_field.minimum_amount,
    approvedLimit: results_field.approved_limit
  }
  const result = CreditCardContratResponseSchema.safeParse(contract)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const requestCreditCard = async ({ applyId, amount }: { applyId: number, amount: number }) => {
  const { data } = await http.post<DataRecord>('/card', { body: { purchase_limit: amount, apply_id: applyId } })
  return data.financial_product_id.uuid
}

const requestSavingAccount = async () => {
  const { data } = await http.post<DataRecord>('/account/apply', { body: { details_field: { }, auto_create_product: true  } })
  const { data: { financial_product_id } } = await http.post<DataRecord>('/account', { body: { apply_id: data.id } })
  const { uuid: id } = financial_product_id
  return id
}
export type RequestCertificateProps = { 
  amount: number 
  duration: number 
  accountToDebitUUID: string
  paymemtMethod: string
  accountToCreditUUID?: string
  autoRenewDuration?: number 
}
const applyDepositCertificate = async ({ amount, duration, accountToDebitUUID, paymemtMethod, accountToCreditUUID, autoRenewDuration }: RequestCertificateProps) => {
  const body = {
    amount: amount,
    duration: duration,
    account_to_debit_uuid: accountToDebitUUID,
    payment_method: paymemtMethod,
    account_to_credit_uuid: accountToCreditUUID,
    auto_renew_duration: autoRenewDuration
  }
  const { data } = await http.post<DataRecord>('/certificate/apply', { body: body })

  const result = CertificateApplicationSchema.safeParse(extractCertificateInfo(data))
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const requestDepositCertificate = async ({ applyId }: { applyId: number }) =>  {
  const { data } = await http.post<DataRecord>('/certificate', { body: { apply_id: applyId } })
  return data.financial_product_id.uuid
}

function extractCertificateInfo(response: DataRecord): CertificateApplication {
  const details = response.results_field.details  
  const automaticRenewal = response.results_field.auto_renew_duration
  return {
    id: response.id,
    status: response.financial_product_request_status_id.name,
    amount: fmCurrency(response.results_field.amount),
    term: `${response.results_field.duration} months`,
    rate: details.rate,
    amountToBeGeneratedMonthly: fmCurrency(details.interest_to_gain_monthly),
    ratePayment: response.details_field.payment_method,
    cancellationPenalty: response.results_field.cancellation_penalty.applies
      ? `${response.results_field.cancellation_penalty.percentage}%`
      : 'No penalty',
    interestPaymentDate: `Day ${details.interest_payment_date} of each month`,
    automaticRenewal:  automaticRenewal ? `${response.results_field.auto_renew_duration} months` : 'N/A',
    accountToBeDebited: response.results_field.account_to_debit_uuid
  }
}

const cancelSavingAccount = async ({ id }: { id: string }) => {
  try {
    const response = await http.delete(`/account/${id}/cancel`)
    toast.success('Account cancelled successfully')
    return response
  } catch (error) {
    toast.error('Error canceling account')
  }
}

const cancelCertificate = async ({ id, accountId }: { id: string, accountId: string }) => {
  const response = await http.post(`/certificate/${id}/cancel`, { body: { account_uuid: accountId } })
  return response
}

const cancelCreditCard = async ({ id }: { id: string }) => {
  const response = await http.delete(`/card/${id}`)
  return response
}

const blockCreditCard = async ({ id }: { id: string }) => {
  const response = await http.post(`/card/${id}/block`, { body: {} })
  return response
}

const activeCreditCard = async ({ id }: { id: string }) => {
  const response = await http.post(`/card/${id}/active`, { body: {} })
  return response
}

const blockSavingAccount = async ({
  id,
  status = true
}: {
  id: string;
  status?: boolean;
}) => {
  const response = await http.post(`/account/${id}/block`, {
    body: { status: status }
  })
  return response
}

const activeSavingAccount = async ({
  id,
  status = true
}: {
  id: string;
  status?: boolean;
}) => {
  const response = await http.post(`/account/${id}/active`, {
    body: { status: status }
  })
  return response
}

const getAccount = async(id: string): Promise<Account | undefined> => {
  const { account } = await http.get<DataRecord>(`/accounts/${id}`, {})
  const currency = jsonToCurrency(account.currency)
  const updatedAccount = { 
    ...account, 
    currency,
    currentQRCodeIdCreation: isNaN(Date.parse(account.currentQRCodeId)) ? (new Date()).toISOString() : account.currentQRCodeId
  }
  const result = AccountSchema.safeParse(updatedAccount)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const updateQRCodeReceive = async({ id }: { id: string }) => {
  await http.put(`/account/${id}/qr`, { body: { } })
}
const getCreditCard = async(id: string): Promise<Card | undefined> => {
  const { card } = await http.get<DataRecord>(`/cards/${id}`, {})
  const result = CardSchema.safeParse(card)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const getCertificate = async(id: string): Promise<DepositCertificate | undefined> => {
  const { certificate } = await http.get<DataRecord>(`/certificates/${id}`, {})
  const result = DepositCertificateSchema.safeParse(jsonToCertificate(certificate))
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data 
}

const jsonToCertificate = (data: DataRecord) => {
  const now = new Date()
  const thisDate = new Date(now)
  thisDate.setDate(data.dayOfPayment)

  const nextPaymentDate = thisDate < now ? new Date(thisDate.setMonth(thisDate.getMonth() + 1)) : thisDate
  const res = {
    id: data.id,
    paymentInterestType: data.paymentInterestType.charAt(0).toUpperCase() + data.paymentInterestType.slice(1),
    interestRate: data.interestRate,
    amount: data.amount,
    endDate: data.endDate,
    accumulatedInterest: data.accumulatedInterest,
    currentAmount: data.currentAmount,
    userId: data.userId,
    duration: data.duration,
    shortNumber: data.shortNumber,
    number: data.shortNumber,
    balanceAvailable: 0,
    accountToCredit: data.accountToCredit,
    statusId: data.statusId,
    startDate: data.startDate,
    dayOfPayment: data.dayOfPayment,
    nextPaymentDate: nextPaymentDate.toString(),
    status: data.status.toLowerCase(),
    monthlyInterestAmount: data.monthlyInterestAmount,
    productType: 'Certificate of Deposit',
    typeLocation: 'certificates',
    createdAt: data.createdAt,
    updatedAt: data.updatedAt
  }
  return res
}

const jsonToCurrency = (data: DataRecord) => {
  const res = {
    isoCode: data.iso_code,
    name: data.name,
    symbol: data.symbol
  }
  return res
}

const getCancellationPenaltyCertificate = async (id: string) => {
  const response = await http.get<DataRecord>(`/certificate/${id}/cancelation_penalty`, { djangoAPI: true })
  const { data } = response

  const { interest_gained_not_paid, current_amount, total_to_deposit, penalty_amount, percentage, balance  } = data

  const cancellation = {
    interestGainedNotPaid: interest_gained_not_paid,
    currentAmount: current_amount,
    totalToDeposit: total_to_deposit,
    penaltyAmount: penalty_amount,
    percentage: percentage,
    balance: balance
  }

  const result = CancelationPenaltyCertificateSchema.safeParse(cancellation)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data   
}

const updateCertificateTypePayment = async({ id, paymentMethod, accountToCreditUUID }: { id: string, paymentMethod: string, accountToCreditUUID?: string }) => {
  if(paymentMethod === 'credit' && !accountToCreditUUID) {
    toast.error('Error, account to credit is required')
    return
  }
  const params: {payment_method: string, account_to_credit_uuid?: string} = {
    payment_method: paymentMethod
  }
  if(paymentMethod === 'credit') {
    params.account_to_credit_uuid = accountToCreditUUID
  }
  await http.put<DataRecord>(`/certificate/${id}`, { body: params })
}

const getAccountTypes = async (): Promise<AccountType[] | undefined> => {
  const { data } = await http.get<DataRecord>('/beneficiaries/types', { djangoAPI: true })
  const accountTypes = data.map((accountType: DataRecord) => ({
    id: accountType.id,
    name: accountType.name,
    description: accountType.description,
    activated: accountType.activated,
    createdAt: accountType.created_at,
    updatedAt: accountType.updated_at
  }))

  const result = AccountTypeSchema.array().safeParse(accountTypes)
  if (!result.success) {
    console.error('API response structure is not as expected:', result.error.message)
    toast.error('API response structure is not as expected')
    return
  }
  return result.data
}

export { activeCreditCard, activeSavingAccount, applyDepositCertificate, blockCreditCard, blockSavingAccount, cancelCertificate, cancelCreditCard, cancelSavingAccount, getAccount, getAccountTypes, getCancellationPenaltyCertificate, getCertificate, getCreditCard, getProducts, requestCreditCard, requestCreditCardApply, requestDepositCertificate, requestSavingAccount, updateCertificateTypePayment, updateQRCodeReceive }

