import { Logo } from '@/components/icons/Logo'
import { Button } from '@/components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { DepositCertificate } from '@/types/product'
import { useLocation } from 'wouter'

type Props = {
  certificate: DepositCertificate
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

export const CreatedCertificate = ({ certificate }: Props) => {
  const [, setLocation] = useLocation()
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle>Deposit certificate</DialogTitle>
      </DialogHeader>
      <div className='flex flex-col gap-3 py-4 '>
        <div>
          <h2 className='font-semibold text-xl text-primary text-center'>Congratulations, your <span>certificate</span> have been created :)</h2>
          <div className='flex justify-center mt-2'><Logo size={80} /></div>
        </div>
        <div className='text-center'>
          <h2 className='font-semibold text-xl text-primary'>{certificate?.shortNumber}</h2>
          <span className='text-sm'>Certificate number</span>
        </div>
        <p className='text-sm text-center'>Boom! Your certificate and its ready and rolling. Congratulations!, Now you are a step closer to conqueer your finantial goals. Let{'\''}s earn money!</p>
      </div>
      <DialogFooter>
        <div className='flex flex-col items-center gap-4'>
          <Button onClick={() => { setLocation(`/products/certificates/${certificate?.id}`) }} className='w-24 h-10 rounded-xl text-md dark:bg-primary dark:text-white dark:hover:bg-primary/90'>Details</Button>
        </div>
      </DialogFooter>
    </>
  )
}