
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyAfrFHPhr-q8vC8G10Qm160AVtQ_NpfZEw',
  authDomain: 'flutter-lira-dev.firebaseapp.com',
  databaseURL: 'https://flutter-lira-dev-default-rtdb.firebaseio.com',
  projectId: 'flutter-lira-dev',
  storageBucket: 'flutter-lira-dev.appspot.com',
  messagingSenderId: '891304955151',
  appId: '1:891304955151:web:15bf00a3673d3ee482427a',
  measurementId: 'G-XHS38QGYMQ'
}

// const firebaseConfig = {
//   apiKey: 'AIzaSyCKTe2qR4_zR4sufl_SqX3wDjjCm_c-ZQ8',
//   authDomain: 'flutter-dev-82ce1.firebaseapp.com',
//   projectId: 'flutter-dev-82ce1',
//   storageBucket: 'flutter-dev-82ce1.appspot.com',
//   messagingSenderId: '488257677764',
//   appId: '1:488257677764:web:a7a5f69511aea6300edc91'
// }

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const realtime = getDatabase(app)
