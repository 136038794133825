import { WorkingDialog } from '@/components/common/WorkingDialog'
import { Shake } from '@/components/icons/Shake'
import { Spinner } from '@/components/icons/Spinner'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { CertificateApplication } from '@/types/product'
import { useState } from 'react'
import InfoItem from '../../../common/InfoItem'

type Props = {
  aplicationCeretificate: CertificateApplication,
  loading: boolean
  onAccept: () => void
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

export const DepositCertificateContract = ({ aplicationCeretificate, loading, onAccept }: Props) => {
  const [terms, setTerms] = useState(false)
  const handleTermsChange = (checked: boolean) => {
    setTerms(checked)
  }
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle>Deposit Certificate</DialogTitle>
        <Shake className='fill-[#f4c573]/20 dark:fill-[#ffffff]/20 size-28' />
      </DialogHeader>
      <div className='flex flex-col gap-3 py-4'>
        <div>
          <h2 className='font-semibold text-xl'>The new way to shake <span className='text-primary'>hands!</span></h2>
          <p className='text-sm text-slate-900 dark:text-slate-500'>We recomend you to read the whole contract; after all, knowledge is power.</p>
        </div>
        <h2 className='font-semibold text-xl'>Simple contract!</h2>
        <div className='grid grid-cols-4 gap-3 text-sm'>
          {/* <InfoItem label='Requested product' value='Credit card' /> */}
          <InfoItem label='Amount' value={aplicationCeretificate!.amount} />
          <InfoItem label='Term' value={aplicationCeretificate!.term} />
          <InfoItem label='Rate' value={aplicationCeretificate!.rate} />
          <InfoItem label='Generated monthly' value={aplicationCeretificate!.amountToBeGeneratedMonthly} />
          <InfoItem label='Rate payment' value={aplicationCeretificate!.ratePayment} />
          <InfoItem label='Cancellation penalty' value={aplicationCeretificate!.cancellationPenalty} />
          <InfoItem label='Interest payment date' value={aplicationCeretificate!.interestPaymentDate} />
          <InfoItem label='Automatic renewal' value={aplicationCeretificate!.automaticRenewal} />
          <InfoItem label='Account to be debited' value={aplicationCeretificate!.accountToBeDebited} />
          {/* <InfoItem label='' value='Customer support (Bot, Chat, Email, Phone)' /> */}
        </div>
        <WorkingDialog>
          <div className='text-primary text-center text-sm font-semibold mt-4'>View whole contract</div>
        </WorkingDialog>
      </div>
      <DialogFooter>
        <form onSubmit={(e) => e.preventDefault()} className='flex flex-col items-center gap-4 w-full'>
          <div className='flex items-center gap-4'>
            <Checkbox id='accept-terms' checked={terms} onCheckedChange={handleTermsChange} />
            <label htmlFor='accept-terms' className='text-xs'>By accepting, I confirm that I have carefully read and understood the terms and conditions of the contract.</label>
          </div>
          <Button className='w-20' disabled={loading || !terms} onClick={onAccept}>
            {loading ? <Spinner className='h-4 w-4 animate-spin' /> : 'Accept'}
          </Button>
        </form>
      </DialogFooter>
    </>
  )
}