type Props = {
  className?: string
  size?: number
}
const MoneyBag = (props: Props) => {
  const { size, className } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className={className} width={size} height={size} viewBox='0 0 45.262 60'>
      <g id='money_bag' data-name='money bag' transform='translate(-167.369 80)'>
        <g id='Group_1469' data-name='Group 1469'>
          <path id='Path_1237' data-name='Path 1237' d='M176.331-74.071a12,12,0,0,1,5.386,8.459h16.392a11.448,11.448,0,0,1,2.8-6.8c2.145-1.826,3.516-3.241,2.374-3.956-.89-.566-4.3-.4-9.295,1.763,0,0-1.619-5.395-3.956-5.395s-4.132,5.418-4.132,5.418a16.715,16.715,0,0,0-7.8-1.916C173.682-76.467,176.331-74.071,176.331-74.071Z' />
          <path id='Path_1238' data-name='Path 1238' d='M209.419-50.162a106.378,106.378,0,0,0-10.482-13.2H180.864s-3.225,3.772-6.314,7.5c-2.544,3.058-10.68,13.565-5.477,24.828s25.285,14.605,35.83,6.754A19.021,19.021,0,0,0,209.419-50.162Zm-13.959,11.2a4.582,4.582,0,0,1-.763,2.069,4.942,4.942,0,0,1-1.676,1.489,5.72,5.72,0,0,1-2.153.647v1.546a.962.962,0,0,1-.263.679.884.884,0,0,1-.664.282.9.9,0,0,1-.673-.282.942.942,0,0,1-.265-.684V-34.8a5.8,5.8,0,0,1-2.261-.848,5.261,5.261,0,0,1-1.735-1.769,4.761,4.761,0,0,1-.546-1.388,1.019,1.019,0,0,1-.014-.183.847.847,0,0,1,.283-.662,1,1,0,0,1,.689-.251h.7a.843.843,0,0,1,.579.212,1.13,1.13,0,0,1,.314.525h0a2.166,2.166,0,0,0,.339.69,2.929,2.929,0,0,0,1.111.949,3.566,3.566,0,0,0,1.568.359,3.617,3.617,0,0,0,1.467-.282,2.453,2.453,0,0,0,.989-.746,2.315,2.315,0,0,0,.435-1.054v-.184a1.876,1.876,0,0,0-.616-1.458,4.072,4.072,0,0,0-1.84-.848l-2.006-.506c-2.628-.664-3.957-2.1-3.957-4.273a4.057,4.057,0,0,1,.63-2.213,4.709,4.709,0,0,1,1.7-1.616,5.961,5.961,0,0,1,2.148-.7v-1.32a.931.931,0,0,1,.282-.678.893.893,0,0,1,.667-.283.875.875,0,0,1,.665.283.951.951,0,0,1,.262.675v1.348a5.206,5.206,0,0,1,2.069.755,4.494,4.494,0,0,1,1.532,1.647,4.6,4.6,0,0,1,.475,1.447.7.7,0,0,1,.014.159.844.844,0,0,1-.3.652,1,1,0,0,1-.681.252h-.681a.847.847,0,0,1-.608-.235,1.066,1.066,0,0,1-.3-.565,2.459,2.459,0,0,0-.266-.63,2.353,2.353,0,0,0-.949-.814,3.448,3.448,0,0,0-1.413-.283,3.244,3.244,0,0,0-1.345.283,2.472,2.472,0,0,0-.975.763,1.75,1.75,0,0,0-.351,1.068c0,.958.667,1.589,2.035,1.93l2.021.495a6.955,6.955,0,0,1,3.233,1.667,3.881,3.881,0,0,1,1.13,2.827,4.475,4.475,0,0,1-.031.644Z' />
        </g>
      </g>
    </svg>
  )
}
export { MoneyBag }
