import { Logo } from '@/components/icons/Logo'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { AccountDetails } from '../AccountDetails'
import { useLocation } from 'wouter'
import { Account } from '@/types/product'

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  account: Account | null
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

const CreatedAccount = ({ open, setOpen, account }: Props) => {
  const [, setLocation] = useLocation()
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='sm:max-w-[500px] bg-[#F7F7FF]'>
        <DialogHeader className='flex flex-col items-center gap-4'>
          <DialogTitle>Saving Account</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col gap-3 py-4 '>
          <div>
            <h2 className='font-semibold text-xl text-primary text-center'>Congratulations, your account have been created :)</h2>
            <div className='flex justify-center mt-2'><Logo size={80} /></div>
          </div>
          <div className='text-center'>
            <h2 className='font-semibold text-xl text-primary'>{account?.number}</h2>
            <span className='text-sm'>Account number</span>
          </div>
          <p className='text-sm text-center'>Boom! Your saving account and its ready and rolling. Congratulations!, Now you are a step closer to conqueer your finantial goals. Let{'\''}s save!</p>
        </div>
        <DialogFooter>
          <div className='flex flex-col items-center gap-4'>
            <AccountDetails account={account??undefined}>
              <Button onClick={() => {setLocation(`/products/accounts/${account?.id}`)}} className='w-24 h-10 rounded-xl text-md dark:bg-primary dark:text-white dark:hover:bg-primary/90'>Details</Button>
            </AccountDetails>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CreatedAccount