import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { NavItem } from '@/types'
import { useState } from 'react'
import { MoneyBag } from '../icons/MoneyBag'
import MenuItem from './MenuItem'

export const SidebarItemOptions = ({ children, item }: { children: React.ReactNode, item: NavItem }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { title, description, children: items } = item
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className='flex flex-col gap-4 py-4'>
          {items!.map(({ title, description, icon: Icon, iconClassName }, index) => (
            <MenuItem
              key={index}
              title={title!}
              description={description ?? ''}
              icon={Icon ? <Icon size={15} /> : <MoneyBag size={15} />}
              iconClassName={iconClassName ?? ''} />
          ))}
        </div>
        <DialogFooter />
      </DialogContent>
    </Dialog>
  )
}
