type Props = {
  className?: string
  size?: number
}
const Cancel = (props: Props) => {
  const { size, className } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' className={className} width={size} height={size} viewBox='0 0 60 59.992'>
      <path id='x' d='M179.69,109.729c-5.915,5.893-11.409,11.408-16.983,16.882a7.6,7.6,0,0,0-2.347,7.993,7.442,7.442,0,0,0,12.666,2.947c4.576-4.445,9.052-8.991,13.547-13.526,1.1-1.109,2.147-2.278,3.286-3.487.689.649,1.159,1.08,1.609,1.528,5.214,5.215,10.409,10.46,15.664,15.634a7.457,7.457,0,0,0,11.749-9.03,13.312,13.312,0,0,0-2-2.417q-7.362-7.413-14.775-14.795c-.45-.45-.88-.92-1.4-1.459.5-.529.919-1,1.359-1.448,5.135-5.136,10.309-10.231,15.385-15.415a7.483,7.483,0,0,0-3.177-12.9,7.642,7.642,0,0,0-7.622,2.467q-7.544,7.611-15.145,15.164c-.45.45-.919.88-1.468,1.409-.52-.489-1-.9-1.439-1.339-5.1-5.085-10.17-10.21-15.295-15.265a7.562,7.562,0,0,0-10.66-.875q-.168.142-.329.3a7.483,7.483,0,0,0-.25,10.579c.082.086.165.169.25.25,5.135,5.2,10.33,10.37,15.524,15.524A16.044,16.044,0,0,0,179.69,109.729Z' transform='translate(-160 -80.004)' />
    </svg>
  )
}
export { Cancel }
