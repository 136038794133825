import { Cancel } from '@/components/icons/Cancel'
import { Alert, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Slider } from '@/components/ui/slider'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'
import { RequestCertificateProps } from '@/services/products'
import { RefreshCcw, RocketIcon } from 'lucide-react'

type Props = {
  onPrevious: () => void
  onContinue: () => void
  params: RequestCertificateProps
  setParams: React.Dispatch<React.SetStateAction<RequestCertificateProps>>
}

type InterestTypes = 'autorenew' | 'cancel'

const interestTypesMessages: Record<InterestTypes, string> = {
  autorenew: 'The certificate will be renewed automatically with the selected term and the rate that applies at the time.',
  cancel: 'The balance of this certificate will be automatically credited to the same account from which 714-553-831 was debited. In the event that account 7147553-831 is not active at the time of cancellation, the balance will be credited to any active account.'
}

export const DepositCertificateExpiresForm = ({ onContinue, onPrevious, params, setParams }: Props) => {
  // const [expires, setExpires] = useState<InterestTypes>('autorenew')
  const handleToogle = (value: InterestTypes) => {
    const newParams = { ...params }
    if (value === 'autorenew') {
      newParams.autoRenewDuration = 1
    } else {
      delete newParams.autoRenewDuration
    }
    setParams(newParams)
  }
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle className='text-slate-800 dark:text-slate-200'>
          Request your <span className='text-primary'>Certificate</span>
        </DialogTitle>
      </DialogHeader>
      <div className='grid grid-cols-2 gap-5 py-4 items-center w-full'>
        <div className='grid grid-cols-1 gap-5 py-4'>
          <div className='grid gap-2'>
            <Label className='font-semibold'>When expires i want to...</Label>
            <ToggleGroup className='flex justify-start gap-3' type='single'
              value={params.autoRenewDuration ? 'autorenew' : 'cancel'} onValueChange={handleToogle}>
              <ToggleGroupItem className='flex gap-3 w-[150px]' variant={'outline'} value='autorenew' aria-label='autorenew'>
                {<RefreshCcw className='text-[#443DC4]' />}Auto-renew
              </ToggleGroupItem>
              <ToggleGroupItem className='flex gap-3 w-[150px]' variant={'outline'} value='cancel' aria-label='cancel'>
                {<Cancel size={16} className='fill-red-600' />}Cancel
              </ToggleGroupItem>
            </ToggleGroup>
          </div>
          {!params.autoRenewDuration && <div className='h-[40px]' />}
          {params.autoRenewDuration && <div className='grid gap-2'>
            <Label className='font-semibold'>Term of renewed <span className='ml-1 bg-purple-100 text-purple-800 px-2 py-[1px] rounded '>{params.autoRenewDuration} months</span></Label>
            <div className='flex gap-3 items-center'>
              <span className='text-sm text-slate-900 dark:text-slate-500'>1</span>
              <Slider
                className='text-primary'
                defaultValue={[params.autoRenewDuration]}
                max={36}
                step={1}
                min={1}
                onValueChange={(value) => setParams({ ...params, autoRenewDuration: value[0] })}
              />
              <span className='text-sm text-slate-900 dark:text-slate-500'>36</span>
            </div>
          </div>}
        </div>
        <Alert className='mt-2'>
          <RocketIcon className='h-4 w-4' />
          <AlertTitle className='text-xs mb-0'>{interestTypesMessages[params.autoRenewDuration ? 'autorenew' : 'cancel']}</AlertTitle>
        </Alert>
      </div>
      <DialogFooter>
        <Button variant={'outline'} onClick={onPrevious}>Previous</Button>
        <Button onClick={onContinue}>Continue</Button>
      </DialogFooter>
    </>
  )
}

// {
//   "message": "Certificate created successfully",
//   "data": {
//       "id": 64,
//       "financial_product_id": {
//           "id": 153,
//           "uuid": "df2a6dfc-050f-4f45-9086-b1ce4ba46524",
//           "created_at": "2024-07-09T10:08:04.802439",
//           "updated_at": "2024-07-09T10:08:04.802460",
//           "user_id": 7,
//           "financial_product_type_id": 5,
//           "financial_product_request_id": 254
//       },
//       "interest_payment_method_id": {
//           "id": 1,
//           "created_at": "2019-01-01T00:00:00",
//           "updated_at": "2019-01-01T00:00:00",
//           "description": "Credito a cuenta",
//           "details": "1-Credito a cuenta = El interes generado sera depositado a tu cuenta lira cada mes."
//       },
//       "created_at": "2024-07-09T10:08:04.825108",
//       "updated_at": "2024-07-09T10:08:04.825129",
//       "description": "Certificate",
//       "apr": 6,
//       "original_amount": "500.0000",
//       "interest_gained_not_paid": "0.0000",
//       "capitalized_amount": "0.0000",
//       "current_amount": "500.0000",
//       "date_of_disbursement": "2024-07-09",
//       "total_interest_paid": "0.0000",
//       "interest_payment_frequency": "Monthly",
//       "date_of_next_interest_payment": 1,
//       "renewal_date": "2025-01-09",
//       "design": {},
//       "settings": {
//           "auto_renew_duration": 12,
//           "cancellation_penalty": {
//               "applies": true,
//               "percentage": 5
//           }
//       },
//       "short_number": "20240709483582",
//       "user_id": 7,
//       "financial_certificate_type_id": 1,
//       "financial_certificate_status_id": 1,
//       "debit_to_financial_product_id": 6,
//       "credit_to_financial_product_id": 114
//   },
//   "status": "success"
// }