import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { getCreditCard, requestCreditCard, requestCreditCardApply } from '@/services/products'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { LoadingRequest } from '../../request/LoadingRequest'
import { ApprovedCreditCard } from './ApprovedCreditCard'
import { CreatedCreditCard } from './CreatedCreditCard'
import { CreditCardContract } from './CreditCardContract'
import { DesiredLimitCreditCard } from './DesiredLimitCreditCard'

type Props = {
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<typeof DialogTrigger>;

export const RequestCreditCard = ({ children }: Props) => {
  const [amount, setAmount] = useState(0)
  const [status, setStatus] = useState<'none' | 'approved' | 'contract' | 'created'>('none')
  const [open, setOpen] = useState(false)
  const useClient = useQueryClient()
  const applyMutation = useMutation({
    mutationFn: (amount: number) => requestCreditCardApply({ amount }),
    onSuccess: () => {
      setStatus('approved')
    },
    onError: (error) => {
      setOpen(false)
      toast.error(error.message || 'Error, could not apply for credit card')
    }
  })

  const requestMutation = useMutation({
    mutationFn: () => requestCreditCard({ applyId: applyMutation.data?.applyId ?? 0, amount }),
    onSuccess: () => {
      useClient.invalidateQueries({ queryKey: ['products'] })
      setStatus('created')
    },
    onError: (error) => {
      setOpen(false)
      toast.error(error.message || 'Error, could not complete request for credit card')
    }
  })

  const { data: card, isLoading, isError: isCardError } = useQuery({
    queryKey: ['credit-card', requestMutation.data],
    queryFn: () => getCreditCard(requestMutation.data),
    enabled: !!requestMutation.data,
    staleTime: 1000 * 60 * 10
  })

  const onSelectLimitAmount = (amount: number) => {
    setAmount(amount)
    setStatus('contract')
  }

  useEffect(() => {
    if (isCardError) {
      setOpen(false)
      toast.error('Error, could not fetch credit card details')
    }
  }, [isCardError])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className='sm:max-w-[500px] sm:min-h-[600px] bg-[#F7F7FF]'>
        {applyMutation.isPending || requestMutation.isPending || isLoading ? (
          <LoadingRequest productName='credit card' />
        ) : (
          <>
            {status === 'none' && (
              <DesiredLimitCreditCard
                onApply={({ amount }) => applyMutation.mutate(amount)}
              />
            )}
            {status === 'approved' && (
              <ApprovedCreditCard
                minApprovedLimit={applyMutation.data?.minimumAmount ?? 0}
                approvedLimit={applyMutation.data?.approvedLimit ?? 0}
                onSelectLimitAmount={onSelectLimitAmount}
              />
            )}
            {status === 'contract' && (
              <CreditCardContract
                contract={applyMutation.data!}
                onRequestCreditCard={() => requestMutation.mutate()}
                loading={applyMutation.isPending}
              />
            )}
            {status === 'created' && card && <CreatedCreditCard card={card} />}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
