import { Shake } from '@/components/icons/Shake'
import { Spinner } from '@/components/icons/Spinner'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { fmCurrency } from '@/lib/constants'
import { CreditCardContrat } from '@/types/product'
import { useState } from 'react'
import InfoItem from '../../../common/InfoItem'

type Props = {
  contract: CreditCardContrat
  loading: boolean,
  onRequestCreditCard: () => void
}
export const CreditCardContract = ({ contract, loading, onRequestCreditCard }: Props) => {
  const [terms, setTerms] = useState(false)

  const handleTermsChange = (checked: boolean) => {
    setTerms(checked)
  }
  if (!contract) return <div>No data</div>
  return (
    <>
      <DialogHeader className='flex flex-col items-center gap-4'>
        <DialogTitle>Credit Card</DialogTitle>
        <Shake className='fill-[#f4c573]/20 dark:fill-[#ffffff]/20 size-28' />
      </DialogHeader>
      <div className='flex flex-col gap-3 py-4'>
        <div>
          <h2 className='font-semibold text-xl'>The new way to shake <span className='text-primary'>hands!</span></h2>
          <p className='text-sm text-slate-900 dark:text-slate-500'>We recomend you to read the whole contract; after all, knowledge is power.</p>
        </div>
        <h2 className='font-semibold text-xl'>Simple contract!</h2>
        <div className='flex flex-col gap-3 text-sm'>
          <InfoItem label='Requested product' value={contract.productName} />
          <InfoItem label='Opening cost' value={fmCurrency(contract.openingCost)} />
          <InfoItem label='Charges and commissions' value={fmCurrency(contract.annualCost)} />
          <InfoItem label='Beneficios' value={contract.benefits.join(', ')} />
          <InfoItem label='Account statement' value='Access to your account statement 24/7' />
          <InfoItem label='Doubts or problems' value={contract.questionsOrIssues.join(', ')} />
        </div>
        <a className='text-primary text-center text-sm font-semibold mt-4' target={contract.contractUrl} href={contract.contractUrl}>View whole contract</a>
      </div>
      <DialogFooter>
        <div className='flex flex-col items-center gap-4'>
          <div className='flex items-center gap-4'>
            <Checkbox checked={terms} onCheckedChange={handleTermsChange} id='accept-terms' />
            <label htmlFor='accept-terms' className='text-xs'>By accepting, I confirm that I have carefully read and understood the terms and conditions of the contract.</label>
          </div>
          <Button className='w-20' disabled={loading || !terms} onClick={onRequestCreditCard}>
            {loading ? <Spinner className='h-4 w-4 animate-spin' /> : 'Accept'}
          </Button>
        </div>
      </DialogFooter>
    </>
  )
}